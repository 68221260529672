import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import IconSelect from 'components/IconSelect';
import SelectTitle from 'components/SelectTitle';

import { AGE_GROUPS } from 'modules/Search/constants';

// Styled Components
const StyledAgeSelect = styled.div`
  display: flex;
  flex-direction: column;
`;

export type AgeSelectProps = {
  selectedAgeGroups: string[];
  isDisabled?: boolean;
  onAgeGroupsChange: (values: string[]) => void;
  theme?: DefaultTheme;
};

// Component
const AgeSelect = ({
  selectedAgeGroups,
  isDisabled,
  onAgeGroupsChange,
}: AgeSelectProps) => {
  const { t } = useTranslation(['search']);

  const ageGroupOptions = Object.values(AGE_GROUPS).map((ageGroup) => ({
    text: t(ageGroup.i18nKey),
    value: ageGroup.value,
  }));

  return (
    <StyledAgeSelect>
      <SelectTitle>{t('search:age_title')}</SelectTitle>
      <IconSelect
        options={ageGroupOptions}
        selectedValues={selectedAgeGroups}
        onChange={onAgeGroupsChange}
        disabled={isDisabled}
      />
    </StyledAgeSelect>
  );
};

export default AgeSelect;
