import FooterBar from 'components/FooterBar';
import Sidebar from 'components/Sidebar';
import AI from 'modules/AI';
import IntegratorClients from 'modules/IntegratorClients';
import ResourceLinks from 'modules/ResourceLinks';
import Search from 'modules/Search';
import SearchCovid from 'modules/SearchCovid';
import SignIn from 'modules/SignIn';
import Trial from 'modules/Trial';
import TrialCovid from 'modules/TrialCovid';
import TrialTagging from 'modules/TrialTagging';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

// Styled Components
const StyledHealMary = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: ${() => (location.pathname != '/admin/signin' ? '80px' : '0px')};
`;

// Component
const HealMary = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname != '/admin/signin' && <Sidebar />}
      <StyledHealMary>
        <Switch>
          <Route path="/admin/covidtrials/:id" component={TrialCovid} />
          <Route path="/admin/search" component={Search} />
          <Route path="/admin/trials/:id" component={Trial} />
          <Route
            path="/admin/integrator_clients"
            component={IntegratorClients}
          />
          <Route path="/admin/covid" component={SearchCovid} />
          <Route path="/admin/ai" component={AI} />
          <Route path="/admin/resources" component={ResourceLinks} />
          <Route path="/admin/trial_tagging" component={TrialTagging} />
          <Route path="/admin/signin" component={SignIn} />
          <Route path="/" render={() => <Redirect to="/admin/signin" />} />
        </Switch>
        <FooterBar />
      </StyledHealMary>
    </>
  );
};

export default HealMary;
