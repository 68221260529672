export default {
  'actions': 'Actions',
  'add_integrator_client': 'Add Integrator Client',
  'choose_image': 'Choose Image',
  'client_added_successfully': 'Client Added Successfully',
  'client_id': 'Client ID',
  'client_updated_successfully': 'Client Updated Successfully',
  'edit_integrator_client': 'Edit Integrator Client',
  'embed_url': 'Embed URL',
  'error_client_id_exists': 'A client with the given ID already exists',
  'error_image_too_big': 'The selected image is too big - must be under 200KB',
  'existing_integrator_clients': 'Existing Integrator Clients',
  'hostname': 'Hostname',
  'logo_image': 'Logo Image',
};
