import Auth from '@aws-amplify/auth';
import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export type AuthError = {
  code: string;
  name: string;
};

export type AuthResult = {
  error?: AuthError;
  success: boolean;
};

export type UserResponse = {
  uid: string;
  email: string;
  last_login: string;
};

/**
 * Sign In a user
 * @param {string} email The email to sign in with
 * @param {string} password The users password
 * @returns {object} If sign in was successful and any error: { success: boolean, error: string }
 */
export async function signIn(
  email: string,
  password: string,
): Promise<AuthResult> {
  try {
    await Auth.signIn({
      username: email,
      password,
    });

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Resend the email verification to the user
 * @param email The user's email address
 * @returns {object} If resending was successful and any error: { success: boolean, error: string }
 */
export async function resendVerification(email: string) {
  try {
    await Auth.resendSignUp(email);

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Verify a user's email
 * @param {string} email Email of user to verify
 * @param {string} code Verification code
 * @returns {object} If verification was successful and any error: { success: boolean, error: string }
 */
export async function verifyEmail(
  email: string,
  code: string,
): Promise<AuthResult> {
  try {
    await Auth.confirmSignUp(email, code);

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Validate sign in was successful with the backend API
 * @returns {boolean} If there is an active signed in session
 */
export async function validateSignIn(): Promise<UserResponse> {
  const response = await authenticatedFetch(`${BASE_URL}/signin`);
  return response.json();
}

/**
 * Sign out the current user
 * @returns {Promise<any>} The result of signing out
 */
export async function signOut() {
  return Auth.signOut();
}

/**
 * Check if there is an active signed in session
 * @returns {boolean} If there is an active signed in session
 */
export async function checkIsSignedIn() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user !== undefined && user !== null;
  } catch (ex) {
    return false;
  }
}
