import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import IconDelete from '../../assets/icon-delete.svg';
import IconAdd from '../../assets/icon-add.svg';

// Styled Components
const StyledMainIconWrapper = styled.div`
  position: relative;
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  display: inline-flex;
  left: 25px;
`;
const StyledIconDelete = styled.img`
  width: 15px;
`;
const StyledIconAdd = styled.img`
  width: 20px;
`;

const StyledIconDeleteButton = styled.button`
  transform: translateY(-30%);
  cursor: pointer;
  background: none;
  border: none;
`;
const StyledIconAddButton = styled.button`
  transform: translateY(-30%);
  cursor: pointer;
  background: none;
  border: none;
`;

export type AddDeleteButtonsProps = {
  theme?: DefaultTheme;
  isDelete?: Boolean;

  onAddClicked: () => void;
  onDeleteClicked?: () => void;
};

// Component
const AddDeleteButtons = ({
  isDelete = true,

  onAddClicked,
  onDeleteClicked,
}: AddDeleteButtonsProps) => {
  const { t } = useTranslation(['trial']);
  return (
    <StyledMainIconWrapper>
      <StyledIconWrapper>
        {isDelete && onDeleteClicked && (
          <StyledIconDeleteButton onClick={onDeleteClicked}>
            <StyledIconDelete src={IconDelete} alt={t('trial:delete_icon')} />
          </StyledIconDeleteButton>
        )}
        <StyledIconAddButton onClick={onAddClicked}>
          <StyledIconAdd src={IconAdd} alt={t('trial:add_icon')} />
        </StyledIconAddButton>
      </StyledIconWrapper>
    </StyledMainIconWrapper>
  );
};

export default AddDeleteButtons;
