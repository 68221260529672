import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import InputWithButton from 'components/InputWithButton';
import IconSelect from 'components/IconSelect';
import SelectTitle from 'components/SelectTitle';

import { CONDITION_GROUPS } from 'modules/Search/constants';

import magnifyImg from '../../assets/icon-magnify.svg';

// Styled Components
const StyledConditionSelect = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextSearch = styled.section`
  display: flex;
  flex-direction: column;

  width: 64%;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.devices.mobile} {
    width: 100%;
  }
`;

const StyledH3 = styled.h3`
  margin-top: 0;
  margin-bottom: 15px;

  text-align: left;

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.regular};
  }
`;

const StyledH5 = styled.h5`
  margin-top: 30px;
  margin-bottom: 30px;

  color: ${(props) => props.theme.colors.subheading};
  text-align: center;
`;

export type ConditionSelectProps = {
  hideTitle?: boolean;
  isDisabled: boolean;
  searchValue: string;
  selectedConditions: string[];
  selectedGroups: string[];
  step: number;

  onConditionsChange: (conditions: string[]) => void;
  onGroupsChange: (groups: string[]) => void;
  onSearch: () => void;
  onSearchTrialId: () => void;
  onSearchChange: (value: string) => void;
  onTrialSearchChange: (value: string) => void;
  theme?: DefaultTheme;
};

// Component
const ConditionSelect = ({
  isDisabled,
  searchValue,
  selectedConditions,
  selectedGroups,
  step,

  onConditionsChange,
  onGroupsChange,
  onSearch,
  onSearchChange,
}: ConditionSelectProps) => {
  const { t } = useTranslation(['search']);

  const conditionGroupsOptions = Object.values(CONDITION_GROUPS).map(
    (conditionGroup) => ({
      text: t(conditionGroup.i18nKey),
      icon: conditionGroup.icon,
      value: conditionGroup.value,
    }),
  );

  // Clear the selected conditions when group changes to avoid group / condition selection mismatch
  const onSelectedGroupsChange = (updatedGroups: string[]) => {
    onConditionsChange([]);
    onGroupsChange(updatedGroups);
  };

  // First get the condition groups
  if (step === 0) {
    return (
      <StyledConditionSelect>
        <StyledTextSearch>
          <label htmlFor="search">
            <StyledH3>{t('search:search_conditions_or_diseases')}</StyledH3>

            <InputWithButton
              buttonText={t('search:search')}
              inputIcon={magnifyImg}
              inputId="search"
              placeholder={t('search:condition_example')}
              value={searchValue}
              onChange={onSearchChange}
              onSubmit={onSearch}
              disabled={selectedGroups.length > 0 || isDisabled}
            />
          </label>
        </StyledTextSearch>
        <StyledH5>{t('search:or_select_any')}</StyledH5>
        <IconSelect
          options={conditionGroupsOptions}
          selectedValues={selectedGroups}
          onChange={onSelectedGroupsChange}
          disabled={searchValue.length > 0 || isDisabled}
        />
      </StyledConditionSelect>
    );
  }

  const conditionOptionsByGroup: Record<
    string,
    { text: string; icon: string; value: string }[]
  > = {};
  Object.values(CONDITION_GROUPS).forEach((conditionGroup) => {
    conditionOptionsByGroup[conditionGroup.value] = Object.values(
      conditionGroup.conditions,
    ).map((condition) => ({
      text: t(condition.i18nKey),
      icon: condition.icon,
      value: condition.value,
    }));
  });

  // Get the condition options for the selected group
  const conditionOptions: { text: string; icon: string; value: string }[] = [];
  selectedGroups.forEach((selectedGroup) => {
    conditionOptions.push(...conditionOptionsByGroup[selectedGroup]);
  });

  // Get the names of the selected groups
  const selectedGroupNames = selectedGroups.map((selectedGroup) =>
    t(CONDITION_GROUPS[selectedGroup as keyof typeof CONDITION_GROUPS].i18nKey),
  );

  // Second, get the conditions themselves
  return (
    <StyledConditionSelect>
      <SelectTitle>
        {t('search:condition_title', {
          groups: selectedGroupNames.join(', '),
        })}
      </SelectTitle>
      <IconSelect
        options={conditionOptions}
        selectedValues={selectedConditions}
        onChange={onConditionsChange}
      />
    </StyledConditionSelect>
  );
};

export default ConditionSelect;
