import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { LinkProps } from 'react-router-dom';

// Styled Components
const StyledFooterBar = styled.footer`
  ${(props) => props.theme.paddedContent}

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-top: 22px;
  padding-bottom: 22px;

  background-color: ${(props) => props.theme.colors.darkBackground};

  color: ${(props) => props.theme.colors.lightText};
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.tablet} {
    flex-direction: column;

    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const StyledCopyright = styled.div`
  @media ${(props) => props.theme.devices.tablet} {
    order: 2;
  }
`;

export type StyledSearchLinkProps = {} & LinkProps;

export type FooterBarProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include props to handle StyledComponents withTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FooterBar = (props: FooterBarProps) => {
  const { t } = useTranslation(['common']);

  return (
    <StyledFooterBar>
      <StyledCopyright>{t('common:copyright')}</StyledCopyright>
    </StyledFooterBar>
  );
};

export default FooterBar;
