import React, { useEffect, useState, useRef } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import Helmet from 'react-helmet';

import areFiltersEqual from 'common/util/areFiltersEqual';
import getFirstValue from 'common/util/getFirstValue';
import TrialSearchResponse from 'common/types/TrialSearchResponse';

import PageController from 'components/PageController/PageController';
import ResultsControls from 'components/ResultsControls';
import ResultsList from 'components/ResultsList/ResultsList';
import TrialResultsLoadingError from 'components/TrialResultsLoadingError';

// Styled Components
const StyledSearchPage = styled.div``;

const StyledSearchContent = styled.div`
  ${(props) => props.theme.paddedContent}

  position: relative;

  max-width: 800px;
  min-height: 270px;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 104px;

  text-align: center;
`;

const StyledTitle = styled.h1`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledResults = styled.div`
  padding-top: 80px;
  padding-bottom: 40px;
  background-color: ${(props) => props.theme.colors.searchBackground};
`;

const StyledSearchResultsContent = styled.div`
  ${(props) => props.theme.paddedContent}

  max-width: 800px;
  margin: 0 auto;

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledResultsListContainer = styled.div`
  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StyledPageControls = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 40px;

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StyledSteps = styled.div`
  ${(props) => props.theme.paddedContent}
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 3px ${(props) => props.theme.colors.darkBackground};
`;

export type SearchFilters = {
  conditions?: string[];
  conditionSearch?: string;
  country?: string;
  page?: number;
  state?: string;
};

export type SearchPageProps = {
  filters: SearchFilters;
  searchTitle: string;
  resultsTitle: string;
  searchControls: React.ReactNode[];
  title: string;
  trialBaseUrl: string;
  onPageSelect: (page: number) => void;
  searchTrials: (filters: SearchFilters) => Promise<TrialSearchResponse>;
  theme?: DefaultTheme;
};

// Component
const SearchPage = ({
  filters,
  searchTitle,
  searchControls,
  title,
  trialBaseUrl,

  onPageSelect,
  searchTrials,
}: SearchPageProps) => {
  const [trialResults, setTrialResults] = useState<TrialSearchResponse>({});
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<any>(null);

  const currentFilters = useRef<SearchFilters>({});

  // Search trials when the filters change
  useEffect(() => {
    const loadTrials = async () => {
      try {
        setIsLoading(true);
        setLoadingError(null);
        const results = await searchTrials(filters);
        setTrialResults(results);
      } catch (ex) {
        setLoadingError(ex);
      } finally {
        setIsLoading(false);
      }
    };

    // Find the first value that has changed
    const firstChangedValue = Object.entries(filters).find(
      ([key, value]) =>
        !areFiltersEqual(
          value,
          currentFilters.current[key as keyof SearchFilters],
        ),
    );

    // Only search if a changed value is found
    if (firstChangedValue) {
      loadTrials();
      currentFilters.current = { ...filters };
    }
  });

  const { data = [], pages, total } = trialResults;

  return (
    <StyledSearchPage>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {searchControls.length && (
        <StyledSearchContent>
          <StyledTitle>{searchTitle}</StyledTitle>
          {searchControls.map(function (step, i) {
            return <StyledSteps key={i}>{step}</StyledSteps>;
          })}
        </StyledSearchContent>
      )}

      <StyledResults>
        <StyledSearchResultsContent>
          <ResultsControls
            isLoading={isLoading}
            loadingError={loadingError}
            page={filters.page}
            pages={pages}
            total={total}
            onPageSelect={onPageSelect}
          />

          {loadingError ? (
            <TrialResultsLoadingError />
          ) : (
            <StyledResultsListContainer>
              <ResultsList
                conditionSearch={
                  filters.conditionSearch ??
                  (filters.conditions
                    ? getFirstValue(filters.conditions)
                    : undefined)
                }
                countrySearch={filters.country}
                isLoading={isLoading}
                results={data}
                stateSearch={filters.state}
                trialBaseUrl={trialBaseUrl}
              />
            </StyledResultsListContainer>
          )}

          <StyledPageControls>
            <PageController
              page={filters.page ?? 0}
              pageCount={pages}
              onPageSelect={onPageSelect}
            />
          </StyledPageControls>
        </StyledSearchResultsContent>
      </StyledResults>
    </StyledSearchPage>
  );
};

export default SearchPage;
