export default function readFile(
  file: File,
  output?: 'binaryString' | 'buffer' | 'dataUrl' | 'text',
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;

    switch (output) {
      case 'dataUrl': {
        reader.readAsDataURL(file);
        break;
      }
      case 'binaryString': {
        reader.readAsBinaryString(file);
        break;
      }
      case 'text': {
        reader.readAsText(file);
        break;
      }
      case 'buffer':
      default: {
        reader.readAsArrayBuffer(file);
      }
    }
  });
}
