/* eslint-disable react/prop-types */
import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors.heading};
  font-family: ${(props) => props.theme.fonts.title};
  font-size: ${(props) => props.theme.fontSizes.h4};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.h4};
  }

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.mobile.h4};
  }
`;

export type LabelProps = {
  as?: any;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'label'>;

// Component
const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ children, ...rest }: LabelProps, ref) => (
    <StyledLabel ref={ref} {...rest}>
      {children}
    </StyledLabel>
  ),
);

Label.displayName = 'Label';
export default Label;
