import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export default function initSentry() {
  // Only run sentry in production builds
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_DEPLOY_ENV,
    release: process.env.REACT_APP_RELEASE_VERSION,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
