import {
  addIntegratorClient,
  getIntegratorClients,
  updateIntegratorClient,
} from 'common/api/integratorClients';
import IntegratorClient from 'common/types/IntegratorClient';
import readFile from 'common/util/readFile';
import Button from 'components/Button';
import LabeledField from 'components/LabeledField';
import LabeledInput from 'components/LabeledInput';
import SuccessMessage from 'components/SuccessMessage';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components';

const StyledIntegratorClients = styled.div`
  ${(props) => props.theme.paddedContent};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding-bottom: 24px;
`;

const StyledHeading = styled.h1`
  margin-top: 24px;
  text-align: center;
`;

const StyledError = styled.div`
  color: ${(props) => props.theme.colors.error};
`;

const StyledClientForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledControls = styled.div`
  display: flex;
  gap: 16px;
  align-self: center;
`;

const StyledImageSection = styled.span`
  display: flex;
  gap: 64px;
  max-height: 128px;
`;

const StyledFileInput = styled.input`
  display: none;
`;

const StyledFileSelectButton = styled(Button)`
  cursor: pointer;
`;

const StyledLogoImage = styled.img`
  max-height: 128px;
  max-width: 512px;
  object-fit: contain;
`;

const StyledRemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.colors.darkButtonBorder};
  border-radius: 16px;
`;

const StyledAddButton = styled(Button)`
  align-self: center;
`;

const StyledMessageArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const StyledClientsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  th {
    text-align: left;
  }

  td,
  th {
    padding: 8px 16px;
  }

  tbody {
    tr:nth-child(even) {
      background-color: ${(props) => props.theme.colors.tableEvenRow};
    }

    tr:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.tableOddRow};
    }
  }
`;

type FormState = {
  client_id: string;
  hostname: string;
  embed_url: string;
  logo_image: string;
  logo_image_type: string;
};

const DEFAULT_FORM_STATE = {
  client_id: '',
  hostname: '',
  embed_url: '',
  logo_image: '',
  logo_image_type: '',
};

export type IntegratorClientsProps = {
  theme?: DefaultTheme;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IntegratorClients = (props: IntegratorClientsProps) => {
  const { t } = useTranslation();
  const [areIntegratorClientsChecked, setAreIntegratorClientsChecked] =
    useState<boolean>(false);
  const [integratorClients, setIntegratorClients] = useState<
    IntegratorClient[]
  >([]);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [fieldsWithError, setFieldsWithError] = useState<(keyof FormState)[]>(
    [],
  );
  const [formState, setFormState] = useState<FormState>(DEFAULT_FORM_STATE);
  const [clientAddedSuccessfully, setClientAddedSuccessfully] =
    useState<boolean>(false);
  const [clientUpdatedSuccessfully, setClientUpdatedSuccessfully] =
    useState<boolean>(false);
  const [editClientDetails, setEditClientDetails] =
    useState<IntegratorClient | null>(null);

  const formRef = useRef<HTMLFormElement | null>(null);
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const isEditing = editClientDetails !== null;

  const getSetFormState =
    (field: keyof FormState, alphaNumericOnly?: boolean) =>
    ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      // Remove from errors on change
      if (fieldsWithError.includes(field)) {
        setFieldsWithError(
          fieldsWithError.filter((fieldWithError) => fieldWithError !== field),
        );
      }

      let sanitizedValue = value;
      if (alphaNumericOnly) {
        sanitizedValue = value.replaceAll(/[^\w]/gi, '');
      }

      setFormState((currentState) => {
        const updatedState = { ...currentState };
        updatedState[field] = sanitizedValue;
        return updatedState;
      });
    };

  useEffect(() => {
    const loadIntegratorClients = async () => {
      try {
        const integratorClientsResponse = await getIntegratorClients();
        if (!integratorClientsResponse) {
          setErrorKey('common:unknown_error');
          return;
        }

        setIntegratorClients(integratorClientsResponse.integrator_clients);
      } catch (ex) {
        setErrorKey('common:unknown_error');
      }
      setAreIntegratorClientsChecked(true);
    };

    loadIntegratorClients();
  }, []);

  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    setErrorKey(null);
    setFieldsWithError([]);

    // Check for required fields
    const updatedFieldsWithError: (keyof FormState)[] = [];
    if (formState.client_id.length < 1) {
      updatedFieldsWithError.push('client_id');
    }
    if (formState.hostname.length < 1) {
      updatedFieldsWithError.push('hostname');
    }

    // Ensure the same client ID isn't re-used
    if (
      integratorClients.find(
        ({ client_id }) =>
          client_id.toLocaleLowerCase() ===
            formState.client_id.toLocaleLowerCase() &&
          (!editClientDetails || client_id !== editClientDetails.client_id),
      )
    ) {
      updatedFieldsWithError.push('client_id');
      setErrorKey('integratorClients:error_client_id_exists');
    }

    if (updatedFieldsWithError.length > 0) {
      setFieldsWithError(updatedFieldsWithError);
      return;
    }

    try {
      if (editClientDetails) {
        const updatedClient = await updateIntegratorClient(
          editClientDetails.id,
          {
            client_id: formState.client_id,
            hostname: formState.hostname,
            embed_url: formState.embed_url,
            logo_image: formState.logo_image,
            logo_image_type: formState.logo_image_type,
          },
        );

        if (!updatedClient) {
          setErrorKey('common:unknown_error');
          return;
        }

        setIntegratorClients((currentClients) =>
          currentClients.map((integratorClient) =>
            integratorClient.id === editClientDetails.id
              ? updatedClient
              : integratorClient,
          ),
        );

        setEditClientDetails(null);
        setClientUpdatedSuccessfully(true);
        setTimeout(() => {
          setClientUpdatedSuccessfully(false);
        }, 5000);
      } else {
        const addedClient = await addIntegratorClient({
          client_id: formState.client_id,
          hostname: formState.hostname,
          embed_url: formState.embed_url,
          logo_image: formState.logo_image,
          logo_image_type: formState.logo_image_type,
        });

        if (!addedClient) {
          setErrorKey('common:unknown_error');
          return;
        }

        setIntegratorClients((currentClients) => [
          ...currentClients,
          addedClient,
        ]);

        setClientAddedSuccessfully(true);
        setTimeout(() => {
          setClientAddedSuccessfully(false);
        }, 5000);
      }
      setFormState(DEFAULT_FORM_STATE);
    } catch (ex) {
      setErrorKey('common:unknown_error');
    }
  };

  const onEditClient = (integratorClient: IntegratorClient) => {
    setFormState({
      client_id: integratorClient.client_id,
      embed_url: integratorClient.embed_url ?? '',
      hostname: integratorClient.hostname,
      logo_image: integratorClient.logo_image ?? '',
      logo_image_type: integratorClient.logo_image_type ?? '',
    });
    setEditClientDetails(integratorClient);

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onCancelEdit = () => {
    setFormState(DEFAULT_FORM_STATE);
    setEditClientDetails(null);
  };

  const onLogoChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    setErrorKey(null);
    if (!evt.target.files || evt.target.files.length === 0) {
      setFormState((currentFormState) => ({
        ...currentFormState,
        logo_image: '',
        logo_image_type: '',
      }));
      return;
    }

    const file = evt.target.files[0];
    const base64File = (await readFile(file, 'dataUrl')) as string;

    if (base64File.length > 262144) {
      setErrorKey('integratorClients:error_image_too_big');
      return;
    }

    const base64Parts = base64File.split(',');
    if (base64File.length < 2) {
      setErrorKey('common:unknown_error');
      return;
    }

    const imageBase64 = base64Parts[1];
    const headerParts = base64Parts[0].split(';');
    if (headerParts.length < 2 || headerParts[0].length < 5) {
      setErrorKey('common:unknown_error');
      return;
    }
    const imageType = headerParts[0].substring(5);

    setFormState((currentFormState) => ({
      ...currentFormState,
      logo_image: imageBase64,
      logo_image_type: imageType,
    }));
  };

  return (
    <StyledIntegratorClients>
      <StyledHeading>{t('common:integrator_clients')}</StyledHeading>
      <StyledClientForm onSubmit={onSubmit} ref={formRef}>
        <h2>
          {isEditing
            ? t('integratorClients:edit_integrator_client')
            : t('integratorClients:add_integrator_client')}
        </h2>
        <LabeledInput
          hasError={fieldsWithError.includes('client_id')}
          label={t('integratorClients:client_id')}
          type="text"
          value={formState.client_id}
          isRequired
          onChange={getSetFormState('client_id', true)}
        />
        <LabeledInput
          hasError={fieldsWithError.includes('hostname')}
          label={t('integratorClients:hostname')}
          type="text"
          value={formState.hostname}
          isRequired
          onChange={getSetFormState('hostname')}
        />
        <LabeledInput
          hasError={fieldsWithError.includes('embed_url')}
          label={t('integratorClients:embed_url')}
          type="text"
          value={formState.embed_url}
          onChange={getSetFormState('embed_url')}
        />
        <StyledImageSection>
          <LabeledField
            hasError={fieldsWithError.includes('logo_image')}
            label={t('integratorClients:logo_image')}
          >
            <StyledFileInput
              ref={imageInputRef}
              accept="image/*"
              type="file"
              onChange={onLogoChange}
            />
            <StyledFileSelectButton
              type="button"
              onClick={() => imageInputRef.current?.click()}
            >
              {t('integratorClients:choose_image')}
            </StyledFileSelectButton>
          </LabeledField>
          {formState.logo_image && formState.logo_image_type && (
            <>
              <StyledLogoImage
                src={`data:${formState.logo_image_type};base64,${formState.logo_image}`}
                alt=""
              />
              <StyledRemoveButton
                title={t('common:delete')}
                onClick={() => {
                  setFormState((currentFormState) => ({
                    ...currentFormState,
                    logo_image: '',
                    logo_image_type: '',
                  }));
                }}
              >
                <span role="img" aria-label={t('common:delete')}>
                  ⤫
                </span>
              </StyledRemoveButton>
            </>
          )}
        </StyledImageSection>
        {isEditing ? (
          <StyledControls>
            <StyledAddButton type="button" onClick={() => onCancelEdit()}>
              {t('common:cancel')}
            </StyledAddButton>
            <StyledAddButton type="submit" dark>
              {t('common:update')}
            </StyledAddButton>
          </StyledControls>
        ) : (
          <StyledAddButton type="submit" dark>
            {t('common:add')}
          </StyledAddButton>
        )}
        <StyledMessageArea>
          {errorKey && <StyledError>{t(errorKey)}</StyledError>}
          <SuccessMessage
            message={
              clientAddedSuccessfully
                ? t('integratorClients:client_added_successfully')
                : t('integratorClients:client_updated_successfully')
            }
            shown={clientAddedSuccessfully || clientUpdatedSuccessfully}
          />
        </StyledMessageArea>
      </StyledClientForm>
      {!areIntegratorClientsChecked && <span>{t('common:loading')}</span>}
      <StyledClientsList>
        <h2>{t('integratorClients:existing_integrator_clients')}</h2>
        <table cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>{t('integratorClients:client_id')}</th>
              <th>{t('integratorClients:hostname')}</th>
              <th>{t('integratorClients:embed_url')}</th>
              <th>{t('integratorClients:actions')}</th>
            </tr>
          </thead>
          <tbody>
            {integratorClients.map((integratorClient) => (
              <tr key={integratorClient.client_id}>
                <td>{integratorClient.client_id}</td>
                <td>{integratorClient.hostname}</td>
                <td>{integratorClient.embed_url}</td>
                <td>
                  <Button
                    type="button"
                    small
                    onClick={() => onEditClient(integratorClient)}
                  >
                    {t('common:edit')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </StyledClientsList>
    </StyledIntegratorClients>
  );
};

export default IntegratorClients;
