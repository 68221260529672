import { ACTION_SIGN_OUT } from 'modules/SignIn/const';

export const MAPS_API_KEY = 'AIzaSyBGFp0l-LyB0bX9BsxYRSGQSR0KkXQEj1E';

export const ELIGIBILITY_VALUES = {
  YES: 'yes',
  NO: 'no',
  UNSURE: 'unsure',
};

export const ELIGIBILITY_RESULTS = {
  MAY_NOT_QUALIFY: 'MAY_NOT_QUALIFY',
  MAY_QUALIFY: 'MAY_QUALIFY',
  UNSURE: 'UNSURE',
};

// Countries that may have different names in the trial
export const API_COUNTRY_MAPPINGS = {
  Korea: [
    'Korea',
    "Korea, Democratic People's Republic of",
    'Korea, Republic of',
  ],
} as Record<string, string[]>;

export const PATHS = {
  COVID: '/covid',
  COVID_SEARCH: '/admin/covid',
  SEARCH: '/admin/search',
  SIGN_IN: '/admin/signin',
  SIGN_OUT: `/signin?action=${ACTION_SIGN_OUT}`,
};

export const TAG_CATEGORIES = {
  STAGE: 'Stage',
  PHASE: 'Phase',
  TYPE: 'Type',
  UNCATEGORIZED: 'Uncategorized',
};
