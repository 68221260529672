/* eslint-disable react/prop-types */
import LabeledField from 'components/LabeledField';
import { LabeledFieldProps } from 'components/LabeledField/LabeledField';
import MultiSelect from 'components/MultiSelect';
import { MultiSelectProps } from 'components/MultiSelect/MultiSelect';
import { DefaultTheme } from 'styled-components/macro';

// Styled Components

export type LabeledMultiSelectProps<T> = {
  theme?: DefaultTheme;
} & LabeledFieldProps &
  MultiSelectProps<T>;

const LabeledMultiSelect = <T extends string | number>({
  hasError,
  isRequired,
  label,
  ...rest
}: LabeledMultiSelectProps<T>) => (
  <LabeledField hasError={hasError} label={label} isRequired={isRequired}>
    <MultiSelect<T> hasError={hasError} {...rest} />
  </LabeledField>
);

LabeledMultiSelect.displayName = 'LabeledMultiSelect';

export default LabeledMultiSelect;
