export default {
  'add': 'Add',
  'ai': 'AI',
  'back': 'Back',
  'cancer_trials': 'Cancer clinical trials',
  'cancel': 'Cancel',
  'close': 'Close',
  'contact_us': 'Contact Us',
  'copied': 'URL Copied',
  'copy_url': 'Copy URL',
  'copyright': '©2022 Heal Mary. All rights reserved.',
  'covid_trials': 'COVID-19 trials',
  'delete': 'Delete',
  'done': 'Done',
  'edit': 'Edit',
  'email': 'Email',
  'email_placeholder': 'your.email@domain.com',
  'ellipsis': '...',
  'heal_mary': 'Heal Mary',
  'integrator_clients': 'Integrator Clients',
  'loading': 'Loading...',
  'medium': 'Medium',
  'move_down': 'Move Down',
  'move_up': 'Move Up',
  'next': 'Next',
  'no': 'No',
  'ok': 'OK',
  'password': 'Password',
  'privacy_policy': 'Privacy Policy',
  'resources': 'Resources',
  'search_clinical_trials': 'Search clinical trials',
  'search_clinical_trials_title_case': 'Search Clinical Trials',
  'what_type_of_clinical_trials': 'What type of clinical trials are you looking for?',
  'yes': 'Yes',
  'your_email': 'Your email address',
  'show_password':'SHOW',
  'hide_password':'HIDE',
  'submit':'Submit',
  'search':'Search',
  'trial_tagging': 'Trial Tagging',
  'unknown_error': 'An unknown error occurred, please try again.',
  'update': 'Update',
  'covid_search':'Covid Search',
  'logout':'Logout'
};
