import IntegratorClient, {
  NewIntegratorClient,
} from 'common/types/IntegratorClient';
import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export type IntegratorClientsResponse = {
  count: number;
  integrator_clients: IntegratorClient[];
};

/**
 * Get Integrator Clients
 * @returns {Promise<IntegratorClientsResponse | null>} The Integrator Clients
 */
export async function getIntegratorClients(): Promise<IntegratorClientsResponse | null> {
  const response = await authenticatedFetch(`${BASE_URL}/integrator_clients`);

  if (!response.ok) {
    return null;
  }
  return response.json();
}

/**
 * Add a new integrator client
 * @param integratorClient The client to add
 * @returns {Promise<IntegratorClient | null>} The added Integrator Client
 */
export async function addIntegratorClient(
  integratorClient: NewIntegratorClient,
): Promise<IntegratorClient | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(`${BASE_URL}/integrator_clients`, {
    method: 'POST',
    headers,
    body: JSON.stringify(integratorClient),
  });

  if (!response.ok) {
    return null;
  }
  return response.json();
}

/**
 * Update an integrator client
 * @param id The client to update
 * @param integratorClient The updated details
 * @returns {Promise<IntegratorClient | null>} The updated Integrator Client
 */
export async function updateIntegratorClient(
  id: number,
  integratorClient: NewIntegratorClient,
): Promise<IntegratorClient | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(
    `${BASE_URL}/integrator_clients/${id}`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(integratorClient),
    },
  );

  if (!response.ok) {
    return null;
  }
  return response.json();
}
