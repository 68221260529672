import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

export const STATUSES = {
  ACTIVE_NOT_RECRUITING: 'Active, not recruiting',
  COMPLETED: 'Completed',
  ENROLLING_BY_INVITATION: 'Enrolling by invitation',
  NOT_YET_RECRUITING: 'Not yet recruiting',
  RECRUITING: 'Recruiting',
  SUSPENDED: 'Suspended',
  TERMINATED: 'Terminated',
  WITHDRAWN: 'Withdrawn',
};

export const STATUS_COLOR_IDS: Record<
  string,
  'statusActive' | 'statusInactive'
> = {
  ACTIVE: 'statusActive',
  INACTIVE: 'statusInactive',
};

export const STATUS_COLORS: Record<string, 'statusActive' | 'statusInactive'> =
  {
    [STATUSES.ACTIVE_NOT_RECRUITING]: STATUS_COLOR_IDS.ACTIVE,
    [STATUSES.COMPLETED]: STATUS_COLOR_IDS.INACTIVE,
    [STATUSES.ENROLLING_BY_INVITATION]: STATUS_COLOR_IDS.ACTIVE,
    [STATUSES.NOT_YET_RECRUITING]: STATUS_COLOR_IDS.INACTIVE,
    [STATUSES.RECRUITING]: STATUS_COLOR_IDS.ACTIVE,
    [STATUSES.SUSPENDED]: STATUS_COLOR_IDS.INACTIVE,
    [STATUSES.TERMINATED]: STATUS_COLOR_IDS.INACTIVE,
    [STATUSES.WITHDRAWN]: STATUS_COLOR_IDS.INACTIVE,
  };

// Styled Components
const StyledStatusIndicator = styled.div<{
  color: 'statusActive' | 'statusInactive';
}>`
  display: flex;
  align-items: center;

  height: 34px;
  padding-left: 14px;
  padding-right: 14px;

  background-color: ${(props) => props.theme.colors[props.color]};
  border-radius: 5px;

  color: ${(props) => props.theme.colors.lightText};
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  white-space: nowrap;
`;

export type StatusIndicatorProps = {
  status: string;
  theme?: DefaultTheme;
};

// Component
const StatusIndicator = ({ status }: StatusIndicatorProps) => (
  <StyledStatusIndicator
    color={STATUS_COLORS[status] || STATUS_COLOR_IDS.INACTIVE}
  >
    {status}
  </StyledStatusIndicator>
);

export default StatusIndicator;
