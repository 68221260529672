import {
  acceptPotentialTrialTag,
  getPotentialTrialTags,
  rejectPotentialTrialTag,
} from 'common/api/tags';
import PotentialTrialTag from 'common/types/PotentialTrialTag';
import Button from 'components/Button';
import PageController from 'components/PageController';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';

const StyledTrialTagging = styled.div`
  ${(props) => props.theme.paddedContent};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding-bottom: 24px;
`;

const StyledHeading = styled.h1`
  margin-top: 24px;
  text-align: center;
`;

const StyledError = styled.div`
  color: ${(props) => props.theme.colors.error};
`;

const StyledDescription = styled.div`
  max-height: 160px;
  overflow: auto;
  white-space: pre;
`;

const StyledPotentialTrialTagList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;

  th {
    text-align: left;
  }

  td,
  th {
    padding: 8px 16px;
  }

  tbody {
    tr:nth-child(even) {
      background-color: ${(props) => props.theme.colors.tableEvenRow};
    }

    tr:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.tableOddRow};
    }
  }
`;

const StyledTag = styled.td`
  white-space: nowrap;
`;

const StyledActions = styled.td`
  max-width: 142px;
  button:first-child {
    margin-bottom: 8px;
  }
`;

const StyledPaging = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoPotentialTags = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type TrialTaggingProps = {
  theme?: DefaultTheme;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TrialTagging = (props: TrialTaggingProps) => {
  const { t } = useTranslation();
  const [arePotentialTrialTagsChecked, setArePotentialTrialTagsChecked] =
    useState<boolean>(false);
  const [potentialTrialTags, setPotentialTrialTags] = useState<
    PotentialTrialTag[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [errorKey, setErrorKey] = useState<string | null>(null);

  const refreshPotentialTrialTags = useCallback(async () => {
    try {
      const potentialTrialTagsResponse = await getPotentialTrialTags(page);
      if (!potentialTrialTagsResponse) {
        setErrorKey('common:unknown_error');
        return;
      }

      setPageCount(potentialTrialTagsResponse.pages || 1);
      setPotentialTrialTags(potentialTrialTagsResponse.data);

      if (potentialTrialTagsResponse.pages < page) {
        setPage(Math.max(page - 1, 1));
      }
    } catch (ex) {
      setErrorKey('common:unknown_error');
    }
    setArePotentialTrialTagsChecked(true);
  }, [page]);

  useEffect(() => {
    refreshPotentialTrialTags();
  }, [refreshPotentialTrialTags]);

  const onAccept = async (trialId: string, tagId: number) => {
    try {
      await acceptPotentialTrialTag(trialId, tagId);
    } catch (ex) {
      setErrorKey('common:unknown_error');
    }
    refreshPotentialTrialTags();
  };
  const onReject = async (trialId: string, tagId: number) => {
    try {
      await rejectPotentialTrialTag(trialId, tagId);
    } catch (ex) {
      setErrorKey('common:unknown_error');
    }
    refreshPotentialTrialTags();
  };

  return (
    <StyledTrialTagging>
      <StyledHeading>{t('common:trial_tagging')}</StyledHeading>
      {!arePotentialTrialTagsChecked && <span>{t('common:loading')}</span>}
      {errorKey && <StyledError>{t(errorKey)}</StyledError>}
      {potentialTrialTags.length === 0 && arePotentialTrialTagsChecked ? (
        <NoPotentialTags>All potential tags have been reviewed</NoPotentialTags>
      ) : (
        <>
          <StyledPaging>
            <PageController
              page={page}
              pageCount={pageCount}
              onPageSelect={setPage}
            />
          </StyledPaging>
          <StyledPotentialTrialTagList>
            <table cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th>{t('tags:trial_id')}</th>
                  <th>{t('tags:trial_title')}</th>
                  <th>{t('tags:trial_description')}</th>
                  <th>{t('tags:tag_name')}</th>
                  <th>{t('tags:actions')}</th>
                </tr>
              </thead>
              <tbody>
                {potentialTrialTags.map((potentialTrialTag) => (
                  <tr
                    key={`${potentialTrialTag.trial_id}_${potentialTrialTag.tag_id}`}
                  >
                    <td>
                      <Link
                        to={`/admin/trials/${potentialTrialTag.trial_id}`}
                        target="_blank"
                      >
                        {potentialTrialTag.trial_id}
                      </Link>
                    </td>
                    <td>{potentialTrialTag.trial.brief_title}</td>
                    <td>
                      <StyledDescription>
                        {potentialTrialTag.trial.detailed_description
                          .textblock ?? '-'}
                      </StyledDescription>
                    </td>
                    <StyledTag>{potentialTrialTag.tag.name}</StyledTag>
                    <StyledActions>
                      <Button
                        type="button"
                        small
                        onClick={() =>
                          onAccept(
                            potentialTrialTag.trial_id,
                            potentialTrialTag.tag_id,
                          )
                        }
                      >
                        {t('tags:accept')}
                      </Button>
                      <Button
                        type="button"
                        small
                        onClick={() =>
                          onReject(
                            potentialTrialTag.trial_id,
                            potentialTrialTag.tag_id,
                          )
                        }
                      >
                        {t('tags:reject')}
                      </Button>
                    </StyledActions>
                  </tr>
                ))}
              </tbody>
            </table>
          </StyledPotentialTrialTagList>
          <StyledPaging>
            <PageController
              page={page}
              pageCount={pageCount}
              onPageSelect={setPage}
            />
          </StyledPaging>
        </>
      )}
    </StyledTrialTagging>
  );
};

export default TrialTagging;
