import TrialFilters from 'common/types/TrialFilters';
import TrialResponse from 'common/types/TrialResponse';
import TrialUpdate from 'common/types/TrialUpdate';
import TrialSearchResponse from 'common/types/TrialSearchResponse';
import { BASE_URL, RESULTS_PER_PAGE } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export type InterestResponse = {
  success?: boolean;
};

/**
 * Get a list of trials given a set of filters
 * @param {Object} filters The filters
 * @param {int} page The page number to get
 */
export async function getTrials(
  filters: TrialFilters,
  page = 1,
): Promise<TrialSearchResponse> {
  // Transform the filters into query param format (snake case) with page #
  const {
    condition = null,
    phase = null,
    country = null,
    state = null,
    minAge = null,
    maxAge = null,
    gender = null,
    trialId = null,
  } = filters;

  const queryParams = {
    condition,
    phase,
    country,
    state,
    min_age: minAge,
    max_age: maxAge,
    gender,

    page,
    per_page: RESULTS_PER_PAGE,
    trial_search_value: trialId,
  };

  // Build query string with non-empty values
  const queryParamsStrings: string[] = [];
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      queryParamsStrings.push(`${key}=${value}`);
    }
  });
  const queryString = queryParamsStrings.join('&');

  const response = await authenticatedFetch(
    `${BASE_URL}/trials?${queryString}`,
  );

  return response.json();
}

/**
 * Get the details of a trial
 * @param {string} id The ID of the trial
 */
export async function getTrial(id: string): Promise<TrialResponse> {
  const response = await authenticatedFetch(`${BASE_URL}/trials/${id}`);

  return response.json();
}

/**
 * Update the trial details
 * @param {string} id The ID of the trial
 * @param {string} updateDetails The details of trial
 */
export async function updateTrial(
  id: string,
  updateDetails: TrialUpdate,
): Promise<InterestResponse> {
  const details = { id, ...updateDetails };

  if (details.trial_interventions) {
    details.trial_interventions = details.trial_interventions.filter(
      (item) => item.intervention_name && item.intervention_type,
    );
  }

  if (details.locations) {
    details.locations = details.locations.filter(
      (item) => item?.facility?.name && item?.facility?.address?.country,
    );
  }

  if (details.inclusion) {
    details.inclusion = details.inclusion.filter((item) => item);
  }

  if (details.exclusion) {
    details.exclusion = details.exclusion.filter((item) => item);
  }

  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');

  const response = await authenticatedFetch(`${BASE_URL}/updatetrial`, {
    method: 'POST',
    headers,
    body: JSON.stringify(details),
  });

  return response.json();
}
