import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export type LinkDetails = {
  title: string;
  url: string;
};

export type ResourceLink = {
  id: number;
  title: string;
  content: string;
  links: LinkDetails[];
  locale: string;
  order: number;
};

export type ResourceLinksResponse = {
  count: number;
  resource_links: ResourceLink[];
};

/**
 * Get the resource links
 * @returns {Promise<ResourceLinksResponse | null>} The resource links
 */
export async function getResourceLinks(): Promise<ResourceLinksResponse | null> {
  const response = await authenticatedFetch(`${BASE_URL}/resource_links`);

  if (!response.ok) {
    return null;
  }
  return response.json();
}

/**
 * Update the resource links
 * @param resourceLinks The updated version of the resource links
 * @returns {Promise<ResourceLinksResponse | null>} The updated resource links
 */
export async function putResourceLinks(
  resourceLinks: Partial<ResourceLink>[],
): Promise<ResourceLinksResponse | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(`${BASE_URL}/resource_links`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(resourceLinks),
  });

  if (!response.ok) {
    return null;
  }
  return response.json();
}
