import React, { useState } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { getLocation } from 'common/api/location';
import COUNTRIES from 'common/countries';

import Button from 'components/Button';
import Select from 'components/Select';
import SelectTitle from 'components/SelectTitle';

import locationImg from './assets/icon-location.svg';

// Styled Components
const StyledLocationSelect = styled.div``;

const StyledInputGroup = styled.div`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

const StyledLabel = styled.h3`
  margin-bottom: 10px;

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.regular};
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 20px;

  color: ${(props) => props.theme.colors.altLightButtonText};
  background-color: ${(props) => props.theme.colors.altLightButtonBackground};
  border-color: ${(props) => props.theme.colors.altLightButtonBorder};
  border-width: 1px;

  &:hover {
    border-color: ${(props) => props.theme.colors.lightButtonText};
  }
`;

const StyledLocationIcon = styled.img`
  margin-right: 10px;
`;

const StyledErrorMessage = styled.div`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.error};
`;

export type LocationSelectProps = {
  selectedCountry?: string;
  selectedState?: string;
  title?: string;
  isDisabled?: boolean;

  onStateChange: (state: string) => void;
  onCountryAndStateChange: (country: string, state?: string) => void;
  theme?: DefaultTheme;
};

// Component
const LocationSelect = ({
  selectedCountry,
  selectedState,
  title,
  isDisabled,

  onStateChange,
  onCountryAndStateChange,
}: LocationSelectProps) => {
  const { t } = useTranslation(['geo', 'search']);
  const [lookupError, setLookupError] = useState<string | null>(null);

  // Create Country options
  const countryOptions = Object.values(COUNTRIES).map((country) => (
    <option key={country.value} value={country.value}>
      {t(country.i18nKey)}
    </option>
  ));

  // Create State/Province options
  const stateOptions =
    selectedCountry &&
    COUNTRIES[selectedCountry] &&
    COUNTRIES[selectedCountry].states
      ? Object.values(COUNTRIES[selectedCountry].states!)
          .sort((a, b) => {
            if (a.value > b.value) return 1;
            if (b.value > a.value) return -1;
            return 0;
          })
          .map((state) => (
            <option key={state.value} value={state.value}>
              {t(state.i18nKey)}
            </option>
          ))
      : [];

  const lookupLocation = async () => {
    setLookupError(null);
    try {
      const location = await getLocation();

      if (!location.country) {
        setLookupError(t('search:location_lookup_error'));
        return;
      }

      if (!COUNTRIES[location.country]) {
        setLookupError(
          t('search:unsupported_location', { location: location.country }),
        );
        return;
      }

      if (
        !location.state ||
        !COUNTRIES[location.country].states ||
        !COUNTRIES[location.country!].states![location.state]
      ) {
        onCountryAndStateChange(location.country, undefined);
        return;
      }

      onCountryAndStateChange(location.country, location.state);
    } catch (ex) {
      setLookupError(t('search:location_lookup_error'));
    }
  };

  return (
    <StyledLocationSelect>
      <SelectTitle>{title || t('search:location_title')}</SelectTitle>

      <StyledInputGroup>
        <label htmlFor="country">
          <StyledLabel>{t('search:select_your_country')}</StyledLabel>
          <StyledSelect
            id="country"
            onChange={(evt) =>
              onCountryAndStateChange(evt.target.value, undefined)
            }
            value={selectedCountry || ''}
            disabled={isDisabled}
          >
            <option value="">{t('search:all_countries')}</option>
            {countryOptions}
          </StyledSelect>
        </label>
      </StyledInputGroup>

      {stateOptions.length > 0 && (
        <StyledInputGroup>
          <label htmlFor="state">
            <StyledLabel>{t('search:state_province')}</StyledLabel>
            <StyledSelect
              id="state"
              disabled={!selectedCountry}
              onChange={(evt) => onStateChange(evt.target.value)}
              value={selectedState || ''}
            >
              <option value="">{t('search:all_states')}</option>
              {stateOptions}
            </StyledSelect>
          </label>
        </StyledInputGroup>
      )}

      <StyledInputGroup>
        <StyledButton
          type="button"
          disabled={isDisabled}
          onClick={lookupLocation}
        >
          <StyledLocationIcon src={locationImg} alt="" />
          {t('search:lookup_my_location')}
        </StyledButton>
        {lookupError && <StyledErrorMessage>{lookupError}</StyledErrorMessage>}
      </StyledInputGroup>
    </StyledLocationSelect>
  );
};

export default LocationSelect;
