/* eslint-disable camelcase */
import CovidFiltersRequest from 'common/types/CovidFiltersRequest';
import { BASE_URL, RESULTS_PER_PAGE } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

/**
 * Get a list of COVID trials given a set of filters
 * @param {Object} filters The filters
 * @param {int} page The page number to get
 */
export async function getCovidTrials(
  filters: CovidFiltersRequest,
  page: number = 1,
) {
  // Transform the filters into query param format (snake case) with page #
  const {
    age = null,
    country = null,
    state = null,
    in_clinical_trial = null,
    previous_clinical_trial = null,
    in_hospital = null,
    healthcare_worker = null,
    require_ventilator = null,
    have_immune_diseases = null,
    on_chemotherapy = null,
    taking_immunosuppressants = null,
    breastfeeding = null,
  } = filters;

  const queryParams = {
    age,
    country,
    state,
    in_clinical_trial,
    previous_clinical_trial,
    in_hospital,
    healthcare_worker,
    require_ventilator,
    have_immune_diseases,
    on_chemotherapy,
    taking_immunosuppressants,
    breastfeeding,

    page,
    per_page: RESULTS_PER_PAGE,
  };

  // Build query string with non-empty values
  const queryParamsStrings: string[] = [];
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      queryParamsStrings.push(`${key}=${value}`);
    }
  });
  const queryString = queryParamsStrings.join('&');

  const response = await authenticatedFetch(
    `${BASE_URL}/trials?is_covid=True&${queryString}`,
  );

  return response.json();
}

/**
 * Get the details of a COVID trial
 * @param {string} id The ID of the trial
 */
export async function getCovidTrial(id: string) {
  const response = await authenticatedFetch(`${BASE_URL}/trials/${id}`);

  return response.json();
}
