export default {
  'add_link': 'Add Link',
  'add_resource': 'Add Resource',
  'content': 'Content:',
  'links': 'Links:',
  'save': 'Save Resources',
  'save_successful': 'Resources have been successfully updated',
  'title': 'Title:',
  'url': 'URL',
};
