/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components/macro';
import LabeledTextArea from 'components/LabeledTextArea';
import AddDeleteButtons from 'components/AddDeleteButtons/AddDeleteButtons';

const StyledMainWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledInputWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export type EligibilityCriteriaEditProps = {
  eligibilityCriteriaArray: string[] | undefined;
  onChange: (newValue: string[]) => void;
};

// Component
const EligibilityCriteriaEdit = ({
  eligibilityCriteriaArray,
  onChange,
}: EligibilityCriteriaEditProps) => (
  <>
    {eligibilityCriteriaArray &&
      eligibilityCriteriaArray.map((criteria, index) => (
        <StyledMainWrapper key={`${index}`}>
          <StyledInputWrapper>
            <LabeledTextArea
              hasError={false}
              label={''}
              value={criteria}
              height="50px"
              onChange={(evt) => {
                const updatedValue = eligibilityCriteriaArray.map((incl, i) => {
                  if (index === i) return evt.target.value;
                  return incl;
                });
                onChange(updatedValue);
              }}
            />
          </StyledInputWrapper>
          <AddDeleteButtons
            onAddClicked={() => {
              const updatedEligibilityCriteriaArray = [
                ...eligibilityCriteriaArray,
              ];
              updatedEligibilityCriteriaArray.splice(index + 1, 0, '');
              onChange(updatedEligibilityCriteriaArray);
            }}
            onDeleteClicked={() => {
              const updatedEligibilityCriteriaArray = [
                ...eligibilityCriteriaArray,
              ];
              updatedEligibilityCriteriaArray.splice(index, 1);
              onChange(updatedEligibilityCriteriaArray);
            }}
          />
        </StyledMainWrapper>
      ))}
    {(!eligibilityCriteriaArray || eligibilityCriteriaArray.length == 0) && (
      <AddDeleteButtons
        isDelete={false}
        onAddClicked={() => {
          onChange(['']);
        }}
      />
    )}
  </>
);
export default EligibilityCriteriaEdit;
