import PotentialTrialTag from 'common/types/PotentialTrialTag';
import PotentialTrialTagsResponse from 'common/types/PotentialTrialTagsResponse';
import TagsResponse from 'common/types/TagsResponse';
import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

/**
 * Get all available tags
 */
export async function getTags(): Promise<TagsResponse> {
  const response = await authenticatedFetch(`${BASE_URL}/tags`);

  return response.json();
}

/**
 * Get potential tags
 */
export async function getPotentialTrialTags(
  page: number,
): Promise<PotentialTrialTagsResponse> {
  const response = await authenticatedFetch(
    `${BASE_URL}/potential_trial_tags?page=${page}&per_page=10`,
  );

  return response.json();
}

/**
 * Accept a potential tag
 */
export async function acceptPotentialTrialTag(
  trialId: string,
  tagId: number,
): Promise<PotentialTrialTag> {
  const response = await authenticatedFetch(
    `${BASE_URL}/potential_trial_tags/${trialId}/${tagId}/accept`,
    {
      method: 'POST',
    },
  );

  return response.json();
}

/**
 * Reject a potential tag
 */
export async function rejectPotentialTrialTag(
  trialId: string,
  tagId: number,
): Promise<PotentialTrialTag> {
  const response = await authenticatedFetch(
    `${BASE_URL}/potential_trial_tags/${trialId}/${tagId}/reject`,
    {
      method: 'POST',
    },
  );

  return response.json();
}
