import { AiPrompts, doCompletion, getPrompts, setPrompts } from 'common/api/ai';
import Button from 'components/Button';
import LabeledTextArea from 'components/LabeledTextArea';
import SuccessMessage from 'components/SuccessMessage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components';

export type AIProps = {
  theme?: DefaultTheme;
};

const StyledAI = styled.div`
  ${(props) => props.theme.paddedContent};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding-bottom: 24px;
`;

const StyledHeading = styled.h1`
  margin-top: 24px;
  text-align: center;
`;

const StyledPromptsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledMessageArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const StyledCompletionForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledControls = styled.div`
  display: flex;
  gap: 16px;
  align-self: center;
`;

const StyledError = styled.div`
  color: ${(props) => props.theme.colors.error};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AI = (props: AIProps) => {
  const { t } = useTranslation();

  const [aiPrompts, setAiPrompts] = useState<AiPrompts>({
    eligibility_prompt: '',
    objective_prompt: '',
    title_prompt: '',
    version: -1,
  });
  const [promptsUpdatedSuccessfully, setPromptsUpdatedSuccessfully] =
    useState<boolean>(false);

  const [prompt, setPrompt] = useState<string>('');
  const [details, setDetails] = useState<string>('');
  const [completion, setCompletion] = useState<string>('');
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    const loadPrompts = async () => {
      const loadedPrompts = await getPrompts();
      if (loadedPrompts) {
        setAiPrompts(loadedPrompts);
      }
    };

    loadPrompts();
  }, []);

  const onDoCompletion = async () => {
    try {
      setIsProcessing(true);
      const response = await doCompletion(prompt, details);
      setCompletion(response?.completion ?? '[EMPTY RESPONSE]');
    } catch (err: any) {
      setErrorKey('common:unknown_error');
      setCompletion(`ERROR: ${err.toString()}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const onUpdatePrompts = async () => {
    const updatedPrompts = await setPrompts(aiPrompts);
    if (updatedPrompts) {
      setAiPrompts(updatedPrompts);
    }

    setPromptsUpdatedSuccessfully(true);
    setTimeout(() => {
      setPromptsUpdatedSuccessfully(false);
    }, 5000);
  };

  return (
    <StyledAI>
      <StyledHeading>AI</StyledHeading>
      <StyledPromptsForm onSubmit={(evt) => evt.preventDefault()}>
        <h2>Processing Prompts</h2>
        <LabeledTextArea
          height="100px"
          label="Title Prompt"
          value={aiPrompts.title_prompt}
          onChange={(evt) =>
            setAiPrompts({ ...aiPrompts, title_prompt: evt.target.value })
          }
        />
        <LabeledTextArea
          height="100px"
          label="Objective Description Prompt"
          value={aiPrompts.objective_prompt}
          onChange={(evt) =>
            setAiPrompts({ ...aiPrompts, objective_prompt: evt.target.value })
          }
        />
        <LabeledTextArea
          height="100px"
          label="Eligibility Prompt"
          value={aiPrompts.eligibility_prompt}
          onChange={(evt) =>
            setAiPrompts({ ...aiPrompts, eligibility_prompt: evt.target.value })
          }
        />
        <StyledControls>
          <Button type="button" onClick={onUpdatePrompts}>
            Update Prompts
          </Button>
        </StyledControls>

        <StyledMessageArea>
          <SuccessMessage
            message="The prompts have been updated"
            shown={promptsUpdatedSuccessfully}
          />
        </StyledMessageArea>
      </StyledPromptsForm>

      <StyledCompletionForm>
        <h2>Playground</h2>
        <h3>Prompt</h3>
        <LabeledTextArea
          height="100px"
          label="Prompt"
          value={prompt}
          onChange={(evt) => setPrompt(evt.target.value)}
        />
        <LabeledTextArea
          height="200px"
          label="Details"
          value={details}
          onChange={(evt) => setDetails(evt.target.value)}
        />

        {errorKey && <StyledError>{t(errorKey)}</StyledError>}

        <StyledControls>
          {isProcessing ? (
            <div>Loading Response...</div>
          ) : (
            <Button disabled={isProcessing} onClick={onDoCompletion}>
              Process
            </Button>
          )}
        </StyledControls>

        <LabeledTextArea
          label="Result"
          height="150px"
          value={completion}
          readOnly
        />
      </StyledCompletionForm>
    </StyledAI>
  );
};

export default AI;
