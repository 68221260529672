/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';

import Label from 'components/Label/Label';

// Styled Components
const StyledLabelText = styled.span<{ hasError: boolean }>`
  display: block;
  margin-bottom: 10px;

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${props.theme.colors.error};
      color: ${props.theme.colors.error};
    `}
`;

const StyledRequiredIndicator = styled.span`
  color: ${(props) => props.theme.colors.requiredIndicator};
`;

export type LabeledFieldProps = {
  hasError?: boolean;
  isRequired?: boolean;
  label: string;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'label'>;

// Component
const LabeledField = React.forwardRef<HTMLLabelElement, LabeledFieldProps>(
  (
    {
      children,
      hasError = false,
      isRequired = false,
      label,
      ...rest
    }: LabeledFieldProps,
    ref,
  ) => (
    <Label ref={ref} {...rest}>
      <StyledLabelText hasError={hasError}>
        {label}
        {isRequired && (
          <StyledRequiredIndicator>&nbsp;*</StyledRequiredIndicator>
        )}
      </StyledLabelText>
      {children}
    </Label>
  ),
);

LabeledField.displayName = 'LabeledField';
export default LabeledField;
