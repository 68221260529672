/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';
import checkImg from './assets/check.svg';

const StyledCheckbox = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  margin-right: 10px;

  text-align: center;

  background-color: ${(props) => props.theme.rawColors.white};
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};
  border-radius: 5px;

  cursor: pointer;
`;

const StyledLabel = styled.span`
  margin-bottom: 5px;
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.subheading};
`;

const StyledWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

// Styled Components
const StyledInput = styled.input<{ hasError: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 5px;

  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};

  transition: opacity 0.4s;

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${props.theme.colors.error};
      color: ${props.theme.colors.error};
    `}

  &[type='text'],
  &[type='email'] {
    box-sizing: border-box;
    width: 100%;
  }

  &[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;

    &:focus + ${StyledCheckbox} {
      border-color: ${(props) => props.theme.colors.active};
    }
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.active};
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export type InputFieldProps = {
  checked?: boolean;
  hasError?: boolean;
  checkboxLabel?: string;
  type: string;
  theme?: DefaultTheme;

  onChangeCheckbox?: (newValue: boolean) => void;
} & React.ComponentPropsWithoutRef<'input'>;

// Component
const InputField = ({
  hasError = false,
  type = 'text',
  checked,
  checkboxLabel,
  onChangeCheckbox = () => {},
  ...rest
}: InputFieldProps) => (
  <>
    <StyledInput type={type} checked={checked} hasError={hasError} {...rest} />
    {type === 'checkbox' && (
      <StyledWrapper>
        <StyledLabel>{checkboxLabel}</StyledLabel>
        <StyledCheckbox onClick={() => onChangeCheckbox(!checked)}>
          {checked && <img src={checkImg} alt="" />}
        </StyledCheckbox>
      </StyledWrapper>
    )}
  </>
);

InputField.displayName = 'InputField';
export default InputField;
