import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import InputWithButton from 'components/InputWithButton';
import magnifyImg from '../../assets/icon-magnify.svg';

// Styled Components
const StyledTrialIdSearch = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextSearch = styled.section`
  display: flex;
  flex-direction: column;

  width: 64%;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.devices.mobile} {
    width: 100%;
  }
`;

export type TrialIdSearchProps = {
  trialId: string;
  onSearchTrialId: () => void;
  onTrialSearchChange: (value: string) => void;
  theme?: DefaultTheme;
};

// Component
const TrialIdSearch = ({
  trialId,
  onSearchTrialId,
  onTrialSearchChange,
}: TrialIdSearchProps) => {
  const { t } = useTranslation(['search']);

  // First get the condition groups
  return (
    <StyledTrialIdSearch>
      <StyledTextSearch>
        <label htmlFor="search">
          <InputWithButton
            buttonText={t('search:search')}
            inputId="search"
            inputIcon={magnifyImg}
            placeholder={t('search:enter_trial_id')}
            value={trialId}
            onChange={onTrialSearchChange}
            onSubmit={onSearchTrialId}
          />
        </label>
      </StyledTextSearch>
    </StyledTrialIdSearch>
  );
};

export default TrialIdSearch;
