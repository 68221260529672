import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { DefaultTheme } from 'styled-components/macro';
import { PATHS } from 'common/constants';

import { getTrials } from 'common/api/trials';
import getFirstValue from 'common/util/getFirstValue';
import TrialUIFilters from 'common/types/TrialUIFilters';

import LocationSelect from 'components/LocationSelect';
import SearchPage from 'components/SearchPage';

import { AGE_GROUPS, DEFAULT_FILTERS } from './constants';

import AgeSelect from './components/AgeSelect';
import ConditionSelect from './components/ConditionSelect';
import GenderSelect from './components/GenderSelect';
import TrialIdSearch from './components/TrialIdSearch';

// Styled Components
// None for now - leveraging shared SearchPage

// Helper
const getFilterUrl = (filters: TrialUIFilters) => {
  const filterParams = queryString.stringify(
    { ...filters },
    { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true },
  );
  return `${PATHS.SEARCH}/?${filterParams}`;
};

export type SearchProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include props to support StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Search = (props: SearchProps) => {
  const { t } = useTranslation(['common', 'search']);
  const history = useHistory();
  const location = useLocation();

  const filters = {
    ...DEFAULT_FILTERS,
    ...queryString.parse(location.search, {
      arrayFormat: 'bracket',
      parseNumbers: true,
      parseBooleans: true,
    }),
  };

  const [conditionSearchValue, setConditionSearchValue] = useState(
    filters.conditionSearch,
  );

  const [trialId, setTrialId] = useState(filters.trialId);

  const setUpdatedFilters = (updatedFilters: TrialUIFilters) => {
    // Update the URL for linking
    history.replace(getFilterUrl(updatedFilters));
  };

  const getSetFilter =
    (filter: string) => (value?: string | string[] | boolean | number) => {
      // Reset page filter if other filter changes
      const updatedPage = filter !== 'page' ? 1 : filters.page;
      const updatedFilters = {
        ...filters,
        page: updatedPage,
        [filter]: value,
        trialId: '',
      };

      setUpdatedFilters(updatedFilters);
    };

  const onCountryAndStateChange = (country: string, state?: string) => {
    const updatedFilters = {
      ...filters,
      country,
      state,
    };

    setUpdatedFilters(updatedFilters);
  };

  // Define controls for each step
  const searchControls = [
    // Step #1: Condition Group
    <TrialIdSearch
      key="trial_id_search"
      trialId={trialId}
      onSearchTrialId={() => {
        history.replace(`${PATHS.SEARCH}/?trialId=${trialId}`);
      }}
      onTrialSearchChange={setTrialId}
    />,

    <ConditionSelect
      key="condition_group"
      searchValue={conditionSearchValue}
      isDisabled={trialId.length > 0}
      selectedConditions={filters.conditions}
      selectedGroups={filters.conditionGroups}
      step={0}
      onConditionsChange={() => {}}
      onGroupsChange={getSetFilter('conditionGroups')}
      // Special case where search has to skip next step
      onSearch={() => {
        const removed_trid_filters = { ...filters, trialId: '' };
        history.replace(
          getFilterUrl({
            ...removed_trid_filters,
            conditionSearch: conditionSearchValue,
          }),
        );
      }}
      onSearchTrialId={() => {
        history.replace(`${PATHS.SEARCH}/?trialId=${trialId}`);
      }}
      onSearchChange={setConditionSearchValue}
      onTrialSearchChange={setTrialId}
    />,

    // Step #2: Condition
    <ConditionSelect
      key="condition"
      searchValue={conditionSearchValue}
      isDisabled={trialId.length > 0}
      selectedConditions={filters.conditions}
      selectedGroups={filters.conditionGroups}
      step={1}
      onConditionsChange={getSetFilter('conditions')}
      onGroupsChange={() => {}}
      onSearch={() => {}}
      onSearchChange={setConditionSearchValue}
      onTrialSearchChange={setTrialId}
      onSearchTrialId={() => {}}
    />,

    // Step #3: Location
    <LocationSelect
      key="location"
      selectedCountry={filters.country}
      selectedState={filters.state}
      onStateChange={getSetFilter('state')}
      onCountryAndStateChange={onCountryAndStateChange}
      isDisabled={trialId.length > 0}
    />,

    // Step #4: Age Group
    <AgeSelect
      key="age"
      selectedAgeGroups={filters.ageGroups}
      onAgeGroupsChange={getSetFilter('ageGroups')}
      isDisabled={trialId.length > 0}
    />,

    // Step #5: Gender
    <GenderSelect
      key="gender"
      selectedGenders={filters.genders}
      onGendersChange={getSetFilter('genders')}
      isDisabled={trialId.length > 0}
    />,
  ];

  return (
    <SearchPage
      filters={filters}
      resultsTitle={t('search:results_title')}
      searchTitle={t('search:going_to_ask_questions')}
      searchControls={searchControls}
      title={t('search:browser_title')}
      trialBaseUrl="/admin/trials"
      onPageSelect={getSetFilter('page')}
      searchTrials={() => {
        const ageGroup = getFirstValue(filters.ageGroups);
        const minAge = ageGroup ? AGE_GROUPS[ageGroup].minAge : undefined;
        const maxAge = ageGroup ? AGE_GROUPS[ageGroup].maxAge : undefined;

        return getTrials(
          {
            condition:
              filters.conditionSearch || getFirstValue(filters.conditions),
            phase: getFirstValue(filters.phases),
            country: filters.country,
            state: filters.state,
            minAge,
            maxAge,
            gender: getFirstValue(filters.genders),
            trialId: filters.trialId,
          },
          filters.page,
        );
      }}
    />
  );
};

export default Search;
