/* eslint-disable quote-props */
import TrialUIFilters from 'common/types/TrialUIFilters';

import headImg from './assets/icon-head.svg';
import neckImg from './assets/icon-neck.svg';
import throatImg from './assets/icon-throat.svg';

import breastImg from './assets/icon-breast.svg';
import esophagusImg from './assets/icon-esophagus.svg';
import lungImg from './assets/icon-lung.svg';

import colonImg from './assets/icon-colon.svg';
import rectalImg from './assets/icon-rectal.svg';

import ovarianImg from './assets/icon-ovarian.svg';
import pancreaticImg from './assets/icon-pancreatic.svg';
import prostateImg from './assets/icon-prostate.svg';

import liverImg from './assets/icon-liver.svg';
import bileductImg from './assets/icon-bileduct.svg';

import leukemiaImg from './assets/icon-leukemia.svg';
import lymphomaImg from './assets/icon-lymphoma.svg';
import melanomaImg from './assets/icon-melanoma.svg';

export type AgeGroup = {
  i18nKey: string;
  value: string;
  minAge: number;
  maxAge?: number;
};

export type Condition = {
  i18nKey: string;
  icon: string;
  value: string;
};

export type ConditionGroup = {
  i18nKey: string;
  icon: string;
  value: string;
  conditions: Record<string, Condition>;
};

export type ValueWithI18n = {
  i18nKey: string;
  value: string;
};

export const DEFAULT_FILTERS = {
  conditionSearch: '',
  conditionGroups: [],
  conditions: [],
  phases: [],
  country: undefined,
  state: undefined,
  ageGroups: [],
  genders: [],
  trialId: '',
  page: 1,
} as TrialUIFilters;

export const CONDITIONS = {
  head: {
    i18nKey: 'search:condition_head',
    icon: headImg,
    value: 'head',
  },
  neck: {
    i18nKey: 'search:condition_neck',
    icon: neckImg,
    value: 'neck',
  },
  throat: {
    i18nKey: 'search:condition_throat',
    icon: throatImg,
    value: 'throat',
  },

  breast: {
    i18nKey: 'search:condition_breast',
    icon: breastImg,
    value: 'breast',
  },
  esophagus: {
    i18nKey: 'search:condition_esophagus',
    icon: esophagusImg,
    value: 'esophagus',
  },
  lung: {
    i18nKey: 'search:condition_lung',
    icon: lungImg,
    value: 'lung',
  },

  colon: {
    i18nKey: 'search:condition_colon',
    icon: colonImg,
    value: 'colon',
  },
  rectal: {
    i18nKey: 'search:condition_rectal',
    icon: rectalImg,
    value: 'rectal',
  },

  ovarian: {
    i18nKey: 'search:condition_ovarian',
    icon: ovarianImg,
    value: 'ovarian',
  },
  pancreatic: {
    i18nKey: 'search:condition_pancreatic',
    icon: pancreaticImg,
    value: 'pancreatic',
  },
  prostate: {
    i18nKey: 'search:condition_prostate',
    icon: prostateImg,
    value: 'prostate',
  },

  bile: {
    i18nKey: 'search:condition_bile_duct',
    icon: bileductImg,
    value: 'bile',
  },
  liver: {
    i18nKey: 'search:condition_liver',
    icon: liverImg,
    value: 'liver',
  },

  leukemia: {
    i18nKey: 'search:condition_leukemia',
    icon: leukemiaImg,
    value: 'leukemia',
  },
  lymphoma: {
    i18nKey: 'search:condition_lymphoma',
    icon: lymphomaImg,
    value: 'lymphoma',
  },
  melanoma: {
    i18nKey: 'search:condition_melanoma',
    icon: melanomaImg,
    value: 'melanoma',
  },
} as Record<string, Condition>;

export const CONDITION_GROUPS = {
  HEAD_NECK_THROAT: {
    i18nKey: 'search:condition_head_neck_throat',
    icon: headImg,
    value: 'HEAD_NECK_THROAT',

    conditions: {
      head: CONDITIONS.head,
      neck: CONDITIONS.neck,
      throat: CONDITIONS.throat,
    },
  },

  BREAST_ESOPHAGUS_LUNG: {
    i18nKey: 'search:condition_breast_esophagus_lung',
    icon: lungImg,
    value: 'BREAST_ESOPHAGUS_LUNG',

    conditions: {
      breast: CONDITIONS.breast,
      esophagus: CONDITIONS.esophagus,
      lung: CONDITIONS.lung,
    },
  },

  COLON_RECTAL: {
    i18nKey: 'search:condition_colon_rectal',
    icon: colonImg,
    value: 'COLON_RECTAL',

    conditions: {
      colon: CONDITIONS.colon,
      rectal: CONDITIONS.rectal,
    },
  },

  OVARIAN_PANCREATIC_PROSTATE: {
    i18nKey: 'search:condition_ovarian_pancreatic_prostate',
    icon: ovarianImg,
    value: 'OVARIAN_PANCREATIC_PROSTATE',

    conditions: {
      ovarian: CONDITIONS.ovarian,
      pancreatic: CONDITIONS.pancreatic,
      prostate: CONDITIONS.prostate,
    },
  },

  BILE_DUCT_LIVER: {
    i18nKey: 'search:condition_bile_duct_liver',
    icon: liverImg,
    value: 'BILE_DUCT_LIVER',

    conditions: {
      bile: CONDITIONS.bile,
      liver: CONDITIONS.liver,
    },
  },

  LEUKEMIA_LYMPHOMA_MELANOMA: {
    i18nKey: 'search:condition_leukemia_lymphoma_melanoma',
    icon: lymphomaImg,
    value: 'LEUKEMIA_LYMPHOMA_MELANOMA',

    conditions: {
      leukemia: CONDITIONS.leukemia,
      lymphoma: CONDITIONS.lymphoma,
      melanoma: CONDITIONS.melanoma,
    },
  },
} as Record<string, ConditionGroup>;

export const PHASES = {
  'Early Phase 1': {
    i18nKey: 'search:phase_early_phase_1',
    value: 'Early Phase 1',
  },
  'Phase 1': {
    i18nKey: 'search:phase_1',
    value: 'Phase 1',
  },
  'Phase 2': {
    i18nKey: 'search:phase_2',
    value: 'Phase 2',
  },
  'Phase 3': {
    i18nKey: 'search:phase_3',
    value: 'Phase 3',
  },
  'Phase 4': {
    i18nKey: 'search:phase_4',
    value: 'Phase 4',
  },
} as Record<string, ValueWithI18n>;

export const AGE_GROUPS = {
  ZERO_TO_SEVENTEEN: {
    i18nKey: 'search:age_zero_to_seventeen',
    value: 'ZERO_TO_SEVENTEEN',
    minAge: 0,
    maxAge: 17,
  },
  EIGHTEEN_TO_SIXTY_FOUR: {
    i18nKey: 'search:age_eighteen_to_sixty_four',
    value: 'EIGHTEEN_TO_SIXTY_FOUR',
    minAge: 18,
    maxAge: 64,
  },
  SIXTY_FIVE_PLUS: {
    i18nKey: 'search:age_sixty_five_plus',
    value: 'SIXTY_FIVE_PLUS',
    minAge: 65,
    maxAge: undefined,
  },
} as Record<string, AgeGroup>;

export const GENDERS = {
  Male: {
    i18nKey: 'search:gender_male',
    value: 'Male',
  },
  Female: {
    i18nKey: 'search:gender_female',
    value: 'Female',
  },
} as Record<string, ValueWithI18n>;
