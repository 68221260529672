import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledIconSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  margin-left: 8.75%;
  margin-right: 8.75%;

  @media ${(props) => props.theme.devices.mobile} {
    justify-content: space-between;

    margin-left: 0;
    margin-right: 0;
  }
`;

const StyledIconOption = styled.button<{ hasIcon: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  width: ${(props) => (props.hasIcon ? '30%' : '27%')};
  height: 78px;
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-bottom: 3%;

  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;

  background-color: ${(props) => props.theme.colors.searchBackground};
  border: 0.5px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 5px;

  box-shadow: 0 0 20px -8px ${(props) => props.theme.rawColors.black20};

  transition: opacity 0.4s;

  &:disabled {
    opacity: 0.4;
  }

  @media ${(props) => props.theme.devices.mobile} {
    width: 47.8%;
    height: 68px;

    margin-left: 0;
    margin-right: 0;
    margin-bottom: 4.4%;

    padding-left: 15px;
    padding-right: 15px;
  }
`;

const StyledSelectionIndicator = styled.span`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  border: 2px solid ${(props) => props.theme.colors.active};
  border-radius: 5px;
`;

const StyledIcon = styled.img`
  width: auto;
  height: 38px;
  margin-right: 20px;

  @media ${(props) => props.theme.devices.mobile} {
    margin-right: 10px;
  }
`;

const StyledLabel = styled.span<{ hasIcon: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch; /* IE11 Fix for not wrapping */
  flex-grow: 1;

  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: left;

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) =>
      props.hasIcon
        ? props.theme.fontSizes.xsmall
        : props.theme.fontSizes.small};
    text-align: center;
  }
`;

export type IconSelectOption = {
  icon?: string;
  text: string;
  value: string;
};

export type IconSelectProps = {
  disabled?: boolean;
  multi?: boolean;
  options: IconSelectOption[];
  selectedValues?: string[];
  onChange: (values: string[]) => void;
  theme?: DefaultTheme;
};

// Component
const IconSelect = ({
  disabled = false,
  multi = false,
  options,
  selectedValues = [],
  onChange,
}: IconSelectProps) => {
  const onValueSelected = (selectedValue: string) => {
    if (multi) {
      const updatedSelectedValues = selectedValues.includes(selectedValue)
        ? [
            ...selectedValues.slice(0, selectedValues.indexOf(selectedValue)),
            ...selectedValues.slice(selectedValues.indexOf(selectedValue) + 1),
          ]
        : [...selectedValues, selectedValue];
      onChange(updatedSelectedValues);
    } else {
      const updatedSelectedValues = selectedValues.includes(selectedValue)
        ? []
        : [selectedValue];
      onChange(updatedSelectedValues);
    }
  };

  const optionElements = options.map((option) => (
    <StyledIconOption
      disabled={disabled}
      hasIcon={option.icon !== undefined}
      key={option.text}
      onClick={() => onValueSelected(option.value)}
    >
      {selectedValues.includes(option.value) && <StyledSelectionIndicator />}
      {option.icon && <StyledIcon src={option.icon} alt="" draggable="false" />}
      <StyledLabel hasIcon={option.icon !== undefined}>
        {option.text}
      </StyledLabel>
    </StyledIconOption>
  ));

  return <StyledIconSelect>{optionElements}</StyledIconSelect>;
};

export default IconSelect;
