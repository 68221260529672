import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';
import LabeledField from 'components/LabeledField';
import SectionSubheading from 'components/SectionSubheading';

const StyledTextArea = styled.textarea<{ height: string; hasError: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  height: ${(props) => props.height};
  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};

  transition: opacity 0.4s;

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${props.theme.colors.error};
      color: ${props.theme.colors.error};
    `}

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.active};
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export type LabeledTextAreaProps = {
  hasError?: boolean;
  label: string;
  height: string;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'textarea'>;

const LabeledTextArea = ({
  hasError = false,
  label,
  height,
  value,
  ...rest
}: LabeledTextAreaProps) => (
  <LabeledField label="">
    <SectionSubheading>{label}</SectionSubheading>
    <StyledTextArea
      height={height}
      hasError={hasError}
      value={value}
      {...rest}
    />
  </LabeledField>
);

export default LabeledTextArea;
