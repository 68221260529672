import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { hydrate, render } from 'react-dom';

import HealMary from 'apps/HealMary';
import initSentry from 'common/sentry/initSentry';
import configureAmplify from 'common/aws/configureAmplify';
import 'react-pro-sidebar/dist/css/styles.css';

initSentry();
configureAmplify();

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
  hydrate(<HealMary />, rootElement);
} else {
  render(<HealMary />, rootElement);
}
