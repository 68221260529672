import React from 'react';
import { Router, withRouter } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { LastLocationProvider } from 'react-router-last-location';
import { ThemeProvider } from 'styled-components/macro';
import 'common/i18n';

// Theme
import healMaryTheme, { GlobalStyle } from 'themes/HealMary';

import HealMary from './HealMary';

// Router setup
const history = createHistory();
const HealMaryWithRouter = withRouter(HealMary);

export default function HealMaryMain() {
  return (
    <Router history={history}>
      <LastLocationProvider>
        <ThemeProvider theme={healMaryTheme}>
          <>
            <GlobalStyle />
            <HealMaryWithRouter />
          </>
        </ThemeProvider>
      </LastLocationProvider>
    </Router>
  );
}
