import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

import dropdownArrowImg from './assets/dropdown-arrow.svg';

// Styled Components
const StyledSelect = styled.select`
  padding: 15px;
  padding-right: 45px;

  background-color: transparent;
  background-image: url(${dropdownArrowImg});
  background-repeat: no-repeat;
  background-position: right -30px center;
  background-origin: content-box;

  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};

  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};

  appearance: none;
`;

export type SelectProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'select'>;

// Component
const Select = ({ children, ...rest }: SelectProps) => (
  <StyledSelect {...rest}>{children}</StyledSelect>
);

export default Select;
