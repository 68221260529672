/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TrialLocation from 'common/types/TrialLocation';
import TrialSearchResult from 'common/types/TrialSearchResult';

import FacilityDetails from 'components/FacilityDetails';
import SectionHeading from 'components/SectionHeading';
import SectionSubheading from 'components/SectionSubheading';
import StatusIndicator from 'components/StatusIndicator';

// Styled Components
const StyledResultsList = styled.ol<{ isLoading?: boolean }>`
  list-style: none;
  padding: 0;
  margin: 0;

  ${(props) =>
    props.isLoading
      ? css`
          transition: filter 0.2s;
          filter: blur(5px);
        `
      : css`
          transition: filter 0.3s;
        `}
`;

const StyledResult = styled.li`
  margin-top: 10px;
  margin-bottom: 10px;

  background-color: ${(props) => props.theme.colors.background};

  border: 2px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 20px;

  box-shadow: 0 0 10px 0 ${(props) => props.theme.rawColors.black20};

  overflow: hidden;
`;

const StyledLoadingIndicator = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 250px;
  margin: 0;

  background-color: ${(props) => props.theme.colors.altSearchBackground};
  font-family: ${(props) => props.theme.fonts.main};
`;

const StyledNoResults = styled.h3`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 400px;
  margin: 0;

  font-family: ${(props) => props.theme.fonts.main};
`;

const StyledTrialLink = styled(Link)`
  display: block;
  padding: 30px;

  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.fontWeights.regular};

  border: none;

  @media ${(props) => props.theme.devices.mobile} {
    padding: 20px 18px;
  }
`;

const StyledSponsoredLabel = styled.div`
  padding-bottom: 20px;

  color: ${(props) => props.theme.colors.label};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
  }
`;

const StyledItemHeading = styled(SectionHeading)`
  flex: 1; /* IE11 Fix for making status indicator flow properly */
`;

const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    margin-top: 20px;
    margin-left: 0;
  }
`;

const StyledStatusLabel = styled(SectionSubheading)`
  padding-right: 10px;

  font-size: ${(props) => props.theme.fontSizes.small};

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.regular};
  }
`;

const StyledConditions = styled.section`
  display: flex;
  flex-direction: row;

  margin-top: 20px;
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.mobile} {
    display: block;

    & > * {
      display: inline;
    }
  }
`;

const StyledMore = styled.span`
  color: ${(props) => props.theme.colors.accentText};
`;

const StyledConditionsList = styled.div`
  padding-left: 5px;
`;

const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 20px;
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
  }
`;

const StyledDetailsSection = styled.section`
  flex-grow: 1;
  flex-basis: 0;

  &:nth-of-type(2) {
    margin-left: 30px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    &:nth-of-type(2) {
      margin-top: 20px;
      margin-left: 0;
    }
  }
`;

const StyledDetailsList = styled.ul`
  padding-left: 25px;
  list-style: initial;
  font-size: ${(props) => props.theme.fontSizes.small};
`;

const StyledMoreListItem = styled.div`
  padding-left: 25px;
  font-size: ${(props) => props.theme.fontSizes.small};
`;

const StyledMoreDetails = styled.div`
  color: ${(props) => props.theme.colors.link};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-decoration: underline;
  text-align: center;
`;

export type ResultsListProps = {
  conditionSearch?: string;
  countrySearch?: string;
  isLoading?: boolean;
  results: TrialSearchResult[];
  stateSearch?: string;
  trialBaseUrl?: string;
  theme?: DefaultTheme;
};

// Component
const ResultsList = ({
  conditionSearch = '',
  countrySearch,
  isLoading = false,
  results,
  stateSearch,
  trialBaseUrl = '/admin/trials',
}: ResultsListProps) => {
  const { t } = useTranslation(['search']);

  if (isLoading && results.length === 0) {
    return (
      <StyledResultsList>
        <StyledResult>
          <StyledLoadingIndicator>
            {t('search:loading_results')}
          </StyledLoadingIndicator>
        </StyledResult>
      </StyledResultsList>
    );
  }

  const resultItems = results.map((result) => {
    const { conditions = [], locations = [] } = result;

    // Order conditions to put those that match the searched condition first
    const matchedConditions: string[] = [];
    const otherConditions: string[] = [];
    let sortedConditions = [];
    if (conditionSearch && conditions) {
      conditions.forEach((condition) => {
        if (
          condition.toLowerCase().indexOf(conditionSearch.toLowerCase()) !== -1
        ) {
          matchedConditions.push(condition);
        } else {
          otherConditions.push(condition);
        }
      });

      sortedConditions = [...matchedConditions, ...otherConditions];
    } else {
      sortedConditions = conditions;
    }

    // Order locations to put those that match the searched country/state first
    const matchedLocations: TrialLocation[] = [];
    const otherLocations: TrialLocation[] = [];
    let sortedLocations = [];
    if (countrySearch && locations) {
      locations.forEach((location) => {
        const {
          facility: { address: { country = null, state = null } = {} } = {},
        } = location;

        // Country / State matching
        if (
          country &&
          country.toLowerCase() === countrySearch.toLowerCase() &&
          (!stateSearch ||
            (state && state.toLowerCase() === stateSearch.toLowerCase()))
        ) {
          matchedLocations.push(location);
        } else {
          otherLocations.push(location);
        }
      });

      sortedLocations = [...matchedLocations, ...otherLocations];
    } else {
      sortedLocations = locations;
    }

    return (
      <StyledResult key={result.id}>
        <StyledTrialLink to={`${trialBaseUrl}/${result.id}`}>
          <article>
            {result.is_sponsored && (
              <StyledSponsoredLabel>
                {t('trial:sponsored_trial')}
              </StyledSponsoredLabel>
            )}
            <StyledHeader>
              <StyledItemHeading>{result.brief_title}</StyledItemHeading>
              <StyledStatus>
                <StyledStatusLabel>{t('trial:status')}</StyledStatusLabel>
                <StatusIndicator status={result.overall_status} />
              </StyledStatus>
            </StyledHeader>

            {sortedConditions && sortedConditions.length > 0 && (
              <StyledConditions>
                <SectionSubheading>
                  {t('trial:conditions_label')}
                </SectionSubheading>
                <StyledConditionsList>
                  {sortedConditions.slice(0, 2).join(', ')}
                  {sortedConditions.length > 2 && (
                    <>
                      {t('search:more_results_connect')}
                      <StyledMore>
                        {t('trial:plus_more', {
                          count: sortedConditions.length - 2,
                        })}
                      </StyledMore>
                    </>
                  )}
                </StyledConditionsList>
              </StyledConditions>
            )}

            <StyledDetailsContainer>
              {result.interventions && result.interventions.length > 0 && (
                <StyledDetailsSection>
                  <SectionSubheading>
                    {t('trial:interventions_label')}
                  </SectionSubheading>
                  <StyledDetailsList>
                    {result.interventions
                      .slice(0, 2)
                      .map((intervention, index) => (
                        <li key={`${intervention.intervention_name}_${index}`}>
                          {`${intervention.intervention_type}: ${intervention.intervention_name}`}
                        </li>
                      ))}
                  </StyledDetailsList>
                  {result.interventions.length > 2 && (
                    <StyledMoreListItem>
                      <StyledMore>
                        {t('trial:plus_more', {
                          count: result.interventions.length - 2,
                        })}
                      </StyledMore>
                    </StyledMoreListItem>
                  )}
                </StyledDetailsSection>
              )}

              {sortedLocations && sortedLocations.length > 0 && (
                <StyledDetailsSection>
                  <SectionSubheading>
                    {t('trial:locations_label')}
                  </SectionSubheading>
                  <StyledDetailsList>
                    {sortedLocations.slice(0, 2).map(
                      ({ facility = null }, index) =>
                        facility && (
                          <li key={`facility-${index}`}>
                            <FacilityDetails
                              facility={facility}
                              isContentOnly
                            />
                          </li>
                        ),
                    )}
                  </StyledDetailsList>
                  {sortedLocations.length > 2 && (
                    <StyledMoreListItem>
                      <StyledMore>
                        {t('trial:plus_more', {
                          count: sortedLocations.length - 2,
                        })}
                      </StyledMore>
                    </StyledMoreListItem>
                  )}
                </StyledDetailsSection>
              )}
            </StyledDetailsContainer>
            <StyledMoreDetails>{t('search:more_details')}</StyledMoreDetails>
          </article>
        </StyledTrialLink>
      </StyledResult>
    );
  });

  return (
    <StyledResultsList isLoading={isLoading}>
      {resultItems}
      {results.length === 0 && (
        <StyledNoResults>{t('search:no_results')}</StyledNoResults>
      )}
    </StyledResultsList>
  );
};

export default ResultsList;
