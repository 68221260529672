export default {
  // Countries
  'belgium': 'Belgium',
  'canada': 'Canada',
  'china': 'China',
  'denmark': 'Denmark',
  'france': 'France',
  'germany': 'Germany',
  'hong_kong': 'Hong Kong',
  'italy': 'Italy',
  'japan': 'Japan',
  'korea': 'Korea, Republic of',
  'mexico': 'Mexico',
  'netherlands': 'Netherlands',
  'norway': 'Norway',
  'singapore': 'Singapore',
  'spain': 'Spain',
  'sweden': 'Sweden',
  'switzerland': 'Switzerland',
  'taiwan': 'Taiwan',
  'united_kingdom': 'United Kingdom',
  'united_states': 'United States',

  // Belgium
  'brussels_capital_region': 'Brussels Capital Region',
  'antwerpen': 'Antwerpen',
  'vlaams brabant': 'Vlaams Brabant',
  'limburg': 'Limburg',
  'oost-vlaanderen': 'Oost-Vlaanderen',
  'west-vlaanderen': 'West-Vlaanderen',
  'brabant wallon': 'Brabant wallon',
  'hainaut': 'Hainaut',
  'liège': 'Liège',
  'luxembourg': 'Luxembourg',
  'namur': 'Namur',

  // China
  'beijing': 'Beijing',
  'tianjin': 'Tianjin',
  'hebei': 'Hebei',
  'shanxi': 'Shanxi',
  'nei_mong': 'Nei Mong',
  'liaoning': 'Liaoning',
  'jilin': 'Jilin',
  'heilongjiang': 'Heilongjiang',
  'shanghai': 'Shanghai',
  'jiangsu': 'Jiangsu',
  'zhejiang': 'Zhejiang',
  'anhui': 'Anhui',
  'fujian': 'Fujian',
  'jiangxi': 'Jiangxi',
  'shandong': 'Shandong',
  'henan': 'Henan',
  'hubei': 'Hubei',
  'hunan': 'Hunan',
  'guangdong': 'Guangdong',
  'guangxi': 'Guangxi',
  'hainan': 'Hainan',
  'chongqing': 'Chongqing',
  'sichuan': 'Sichuan',
  'guizhou': 'Guizhou',
  'yunnan': 'Yunnan',
  'xizang': 'Xizang',
  'shaanxi': 'Shaanxi',
  'gansu': 'Gansu',
  'qinghai': 'Qinghai',
  'ningxia': 'Ningxia',
  'xinjiang': 'Xinjiang',
  // 'taiwan': 'Taiwan', -- already defined as country
  'xianggang': 'Xianggang',
  'aomen': 'Aomen',

  // Denmark
  'nordjylland': 'Nordjylland',
  'midtjylland': 'Midtjylland',
  'syddanmark': 'Syddanmark',
  'hovedstaden': 'Hovedstaden',
  'sjælland': 'Sjælland',

  // France
  'ain': 'Ain',
  'aisne': 'Aisne',
  'allier': 'Allier',
  'alpes-de-haute-provence': 'Alpes-de-Haute-Provence',
  'hautes-alpes': 'Hautes-Alpes',
  'alpes-maritimes': 'Alpes-Maritimes',
  'ardèche': 'Ardèche',
  'ardennes': 'Ardennes',
  'ariège': 'Ariège',
  'aube': 'Aube',
  'aude': 'Aude',
  'aveyron': 'Aveyron',
  'bouches-du-rhône': 'Bouches-du-Rhône',
  'calvados': 'Calvados',
  'cantal': 'Cantal',
  'charente': 'Charente',
  'charente-maritime': 'Charente-Maritime',
  'cher': 'Cher',
  'corrèze': 'Corrèze',
  'côte-d\'or': 'Côte-d\'Or',
  'côtes-d\'armor': 'Côtes-d\'Armor',
  'creuse': 'Creuse',
  'dordogne': 'Dordogne',
  'doubs': 'Doubs',
  'drôme': 'Drôme',
  'eure': 'Eure',
  'eure-et-loir': 'Eure-et-Loir',
  'finistère': 'Finistère',
  'corse-du-sud': 'Corse-du-Sud',
  'haute-corse': 'Haute-Corse',
  'gard': 'Gard',
  'haute-garonne': 'Haute-Garonne',
  'gers': 'Gers',
  'gironde': 'Gironde',
  'hérault': 'Hérault',
  'ille-et-vilaine': 'Ille-et-Vilaine',
  'indre': 'Indre',
  'indre-et-loire': 'Indre-et-Loire',
  'isère': 'Isère',
  'jura': 'Jura',
  'landes': 'Landes',
  'loir-et-cher': 'Loir-et-Cher',
  'loire': 'Loire',
  'haute-loire': 'Haute-Loire',
  'loire-atlantique': 'Loire-Atlantique',
  'loiret': 'Loiret',
  'lot': 'Lot',
  'lot-et-garonne': 'Lot-et-Garonne',
  'lozère': 'Lozère',
  'maine-et-loire': 'Maine-et-Loire',
  'manche': 'Manche',
  'marne': 'Marne',
  'haute-marne': 'Haute-Marne',
  'mayenne': 'Mayenne',
  'meurthe-et-moselle': 'Meurthe-et-Moselle',
  'meuse': 'Meuse',
  'morbihan': 'Morbihan',
  'moselle': 'Moselle',
  'nièvre': 'Nièvre',
  'nord': 'Nord',
  'oise': 'Oise',
  'orne': 'Orne',
  'pas-de-calais': 'Pas-de-Calais',
  'puy-de-dôme': 'Puy-de-Dôme',
  'pyrénées-atlantiques': 'Pyrénées-Atlantiques',
  'hautes-pyrénées': 'Hautes-Pyrénées',
  'pyrénées-orientales': 'Pyrénées-Orientales',
  'bas-rhin': 'Bas-Rhin',
  'haut-rhin': 'Haut-Rhin',
  'rhône': 'Rhône',
  'haute-saône': 'Haute-Saône',
  'saône-et-loire': 'Saône-et-Loire',
  'sarthe': 'Sarthe',
  'savoie': 'Savoie',
  'haute-savoie': 'Haute-Savoie',
  'paris': 'Paris',
  'seine-maritime': 'Seine-Maritime',
  'seine-et-marne': 'Seine-et-Marne',
  'yvelines': 'Yvelines',
  'deux-sèvres': 'Deux-Sèvres',
  'somme': 'Somme',
  'tarn': 'Tarn',
  'tarn-et-garonne': 'Tarn-et-Garonne',
  'var': 'Var',
  'vaucluse': 'Vaucluse',
  'vendée': 'Vendée',
  'vienne': 'Vienne',
  'haute-vienne': 'Haute-Vienne',
  'vosges': 'Vosges',
  'yonne': 'Yonne',
  'territoire de belfort': 'Territoire de Belfort',
  'essonne': 'Essonne',
  'hauts-de-seine': 'Hauts-de-Seine',
  'seine-saint-denis': 'Seine-Saint-Denis',
  'val-de-marne': 'Val-de-Marne',
  'val-d\'oise': 'Val-d\'Oise',

  // Germany
  'brandenburg': 'Brandenburg',
  'berlin': 'Berlin',
  'baden-württemberg': 'Baden-Württemberg',
  'bayern': 'Bayern',
  'bremen': 'Bremen',
  'hessen': 'Hessen',
  'hamburg': 'Hamburg',
  'mecklenburg-vorpommern': 'Mecklenburg-Vorpommern',
  'niedersachsen': 'Niedersachsen',
  'nordrhein-westfalen': 'Nordrhein-Westfalen',
  'rheinland-pfalz': 'Rheinland-Pfalz',
  'schleswig-holstein': 'Schleswig-Holstein',
  'saarland': 'Saarland',
  'sachsen': 'Sachsen',
  'sachsen-anhalt': 'Sachsen-Anhalt',
  'thüringen': 'Thüringen',

  // Hongkong
  // N/A

  // Italy
  'agrigento': 'Agrigento',
  'alessandria': 'Alessandria',
  'ancona': 'Ancona',
  'aosta': 'Aosta',
  'ascoli piceno': 'Ascoli Piceno',
  'l\'aquila': 'L\'Aquila',
  'arezzo': 'Arezzo',
  'asti': 'Asti',
  'avellino': 'Avellino',
  'bari': 'Bari',
  'bergamo': 'Bergamo',
  'biella': 'Biella',
  'belluno': 'Belluno',
  'benevento': 'Benevento',
  'bologna': 'Bologna',
  'brindisi': 'Brindisi',
  'brescia': 'Brescia',
  'barletta-andria-trani': 'Barletta-Andria-Trani',
  'bolzano / bozen (de': 'Bolzano / Bozen (de',
  'cagliari': 'Cagliari',
  'campobasso': 'Campobasso',
  'caserta': 'Caserta',
  'chieti': 'Chieti',
  'caltanissetta': 'Caltanissetta',
  'cuneo': 'Cuneo',
  'como': 'Como',
  'cremona': 'Cremona',
  'cosenza': 'Cosenza',
  'catania': 'Catania',
  'catanzaro': 'Catanzaro',
  'enna': 'Enna',
  'forlì-cesena': 'Forlì-Cesena',
  'ferrara': 'Ferrara',
  'foggia': 'Foggia',
  'firenze': 'Firenze',
  'fermo': 'Fermo',
  'frosinone': 'Frosinone',
  'genova': 'Genova',
  'grosseto': 'Grosseto',
  'imperia': 'Imperia',
  'isernia': 'Isernia',
  'crotone': 'Crotone',
  'lecco': 'Lecco',
  'lecce': 'Lecce',
  'livorno': 'Livorno',
  'lodi': 'Lodi',
  'latina': 'Latina',
  'lucca': 'Lucca',
  'monza e brianza': 'Monza e Brianza',
  'macerata': 'Macerata',
  'messina': 'Messina',
  'milano': 'Milano',
  'mantova': 'Mantova',
  'modena': 'Modena',
  'massa-carrara': 'Massa-Carrara',
  'matera': 'Matera',
  'napoli': 'Napoli',
  'novara': 'Novara',
  'nuoro': 'Nuoro',
  'oristano': 'Oristano',
  'palermo': 'Palermo',
  'piacenza': 'Piacenza',
  'padova': 'Padova',
  'pescara': 'Pescara',
  'perugia': 'Perugia',
  'pisa': 'Pisa',
  'prato': 'Prato',
  'parma': 'Parma',
  'pistoia': 'Pistoia',
  'pesaro e urbino': 'Pesaro e Urbino',
  'pavia': 'Pavia',
  'potenza': 'Potenza',
  'ravenna': 'Ravenna',
  'reggio calabria': 'Reggio Calabria',
  'reggio emilia': 'Reggio Emilia',
  'ragusa': 'Ragusa',
  'rieti': 'Rieti',
  'roma': 'Roma',
  'rimini': 'Rimini',
  'rovigo': 'Rovigo',
  'salerno': 'Salerno',
  'sud sardegna': 'Sud Sardegna',
  'siena': 'Siena',
  'sondrio': 'Sondrio',
  'la spezia': 'La Spezia',
  'siracusa': 'Siracusa',
  'sassari': 'Sassari',
  'savona': 'Savona',
  'taranto': 'Taranto',
  'teramo': 'Teramo',
  'trento': 'Trento',
  'torino': 'Torino',
  'trapani': 'Trapani',
  'terni': 'Terni',
  'treviso': 'Treviso',
  'varese': 'Varese',
  'verbano-cusio-ossola': 'Verbano-Cusio-Ossola',
  'vercelli': 'Vercelli',
  'venezia': 'Venezia',
  'vicenza': 'Vicenza',
  'verona': 'Verona',
  'viterbo': 'Viterbo',
  'vibo valentia': 'Vibo Valentia',

  // Japan
  'hokkaido': 'Hokkaido',
  'aomori': 'Aomori',
  'iwate': 'Iwate',
  'miyagi': 'Miyagi',
  'akita': 'Akita',
  'yamagata': 'Yamagata',
  'fukushima': 'Fukushima',
  'ibaraki': 'Ibaraki',
  'tochigi': 'Tochigi',
  'gunma': 'Gunma',
  'saitama': 'Saitama',
  'chiba': 'Chiba',
  'tokyo': 'Tokyo',
  'kanagawa': 'Kanagawa',
  'niigata': 'Niigata',
  'toyama': 'Toyama',
  'ishikawa': 'Ishikawa',
  'fukui': 'Fukui',
  'yamanashi': 'Yamanashi',
  'nagano': 'Nagano',
  'gifu': 'Gifu',
  'shizuoka': 'Shizuoka',
  'aichi': 'Aichi',
  'mie': 'Mie',
  'shiga': 'Shiga',
  'kyoto': 'Kyoto',
  'osaka': 'Osaka',
  'hyogo': 'Hyogo',
  'nara': 'Nara',
  'wakayama': 'Wakayama',
  'tottori': 'Tottori',
  'shimane': 'Shimane',
  'okayama': 'Okayama',
  'hiroshima': 'Hiroshima',
  'yamaguchi': 'Yamaguchi',
  'tokushima': 'Tokushima',
  'kagawa': 'Kagawa',
  'ehime': 'Ehime',
  'kochi': 'Kochi',
  'fukuoka': 'Fukuoka',
  'saga': 'Saga',
  'nagasaki': 'Nagasaki',
  'kumamoto': 'Kumamoto',
  'oita': 'Oita',
  'miyazaki': 'Miyazaki',
  'kagoshima': 'Kagoshima',
  'okinawa': 'Okinawa',

  // Korea, republic of
  // TBD

  // Mexico
  'aguascalientes': 'Aguascalientes',
  'baja california': 'Baja California',
  'baja california sur': 'Baja California Sur',
  'campeche': 'Campeche',
  'chihuahua': 'Chihuahua',
  'chiapas': 'Chiapas',
  'ciudad de méxico': 'Ciudad de México',
  'coahuila de zaragoza': 'Coahuila de Zaragoza',
  'colima': 'Colima',
  'durango': 'Durango',
  'guerrero': 'Guerrero',
  'guanajuato': 'Guanajuato',
  'hidalgo': 'Hidalgo',
  'jalisco': 'Jalisco',
  'méxico': 'México',
  'michoacán de ocampo': 'Michoacán de Ocampo',
  'morelos': 'Morelos',
  'nayarit': 'Nayarit',
  'nuevo león': 'Nuevo León',
  'oaxaca': 'Oaxaca',
  'puebla': 'Puebla',
  'querétaro': 'Querétaro',
  'quintana roo': 'Quintana Roo',
  'sinaloa': 'Sinaloa',
  'san luis potosí': 'San Luis Potosí',
  'sonora': 'Sonora',
  'tabasco': 'Tabasco',
  'tamaulipas': 'Tamaulipas',
  'tlaxcala': 'Tlaxcala',
  'veracruz de ignacio de la llave': 'Veracruz de Ignacio de la Llave',
  'yucatán': 'Yucatán',
  'zacatecas': 'Zacatecas',

  // Netherlands
  'drenthe': 'Drenthe',
  'flevoland': 'Flevoland',
  'fryslân': 'Fryslân',
  'gelderland': 'Gelderland',
  'groningen': 'Groningen',
  // 'limburg': 'Limburg', -- duplicate in Belgium
  'noord-brabant': 'Noord-Brabant',
  'noord-holland': 'Noord-Holland',
  'overijssel': 'Overijssel',
  'utrecht': 'Utrecht',
  'zeeland': 'Zeeland',
  'zuid-holland': 'Zuid-Holland',

  // Norway
  'østfold': 'Østfold',
  'akershus': 'Akershus',
  'oslo': 'Oslo',
  'hedmark': 'Hedmark',
  'oppland': 'Oppland',
  'buskerud': 'Buskerud',
  'vestfold': 'Vestfold',
  'telemark': 'Telemark',
  'aust-agder': 'Aust-Agder',
  'vest-agder': 'Vest-Agder',
  'rogaland': 'Rogaland',
  'hordaland': 'Hordaland',
  'sogn og fjordane': 'Sogn og Fjordane',
  'møre og romsdal': 'Møre og Romsdal',
  'nordland': 'Nordland',
  'troms': 'Troms',
  'finnmark': 'Finnmark',
  'svalbard': 'Svalbard',
  'jan mayen': 'Jan Mayen',
  'trøndelag': 'Trøndelag',

  // Spain
  'alicante / alacant': 'Alicante / Alacant',
  'albacete': 'Albacete',
  'almería': 'Almería',
  'ávila': 'Ávila',
  'barcelona': 'Barcelona',
  'badajoz': 'Badajoz',
  'biskaia': 'Biskaia',
  'burgos': 'Burgos',
  'a coruña': 'A Coruña',
  'cádiz': 'Cádiz',
  'cáceres': 'Cáceres',
  'ceuta': 'Ceuta',
  'córdoba': 'Córdoba',
  'ciudad real': 'Ciudad Real',
  'castellón / castelló': 'Castellón / Castelló',
  'cuenca': 'Cuenca',
  'las palmas': 'Las Palmas',
  'girona': 'Girona',
  'granada': 'Granada',
  'guadalajara': 'Guadalajara',
  'huelva': 'Huelva',
  'huesca': 'Huesca',
  'jaén': 'Jaén',
  'lleida': 'Lleida',
  'león': 'León',
  'la rioja': 'La Rioja',
  'lugo': 'Lugo',
  'madrid': 'Madrid',
  'málaga': 'Málaga',
  'melilla': 'Melilla',
  'murcia': 'Murcia',
  'navarra / nafarroa': 'Navarra / Nafarroa',
  'asturias': 'Asturias',
  'ourense': 'Ourense',
  'palencia': 'Palencia',
  'balears': 'Balears',
  'pontevedra': 'Pontevedra',
  'cantabria': 'Cantabria',
  'salamanca': 'Salamanca',
  'sevilla': 'Sevilla',
  'segovia': 'Segovia',
  'soria': 'Soria',
  'gipuzkoa': 'Gipuzkoa',
  'tarragona': 'Tarragona',
  'teruel': 'Teruel',
  'santa cruz de tenerife': 'Santa Cruz de Tenerife',
  'toledo': 'Toledo',
  'valencia / valència': 'Valencia / València',
  'valladolid': 'Valladolid',
  'álava / araba': 'Álava / Araba',
  'zaragoza': 'Zaragoza',
  'zamora': 'Zamora',

  // Sweden
  'stockholm': 'Stockholm',
  'västerbotten': 'Västerbotten',
  'norrbotten': 'Norrbotten',
  'uppland': 'Uppland',
  'södermanland': 'Södermanland',
  'östergötland': 'Östergötland',
  'jönköping': 'Jönköping',
  'kronoberg': 'Kronoberg',
  'kalmar': 'Kalmar',
  'gotland': 'Gotland',
  'blekinge': 'Blekinge',
  'skåne': 'Skåne',
  'halland': 'Halland',
  'västra götaland': 'Västra Götaland',
  'värmland': 'Värmland',
  'örebro': 'Örebro',
  'västmanland': 'Västmanland',
  'dalarna': 'Dalarna',
  'gävleborg': 'Gävleborg',
  'västernorrland': 'Västernorrland',
  'jämtland': 'Jämtland',

  // Switzerland
  'aargau': 'Aargau',
  'appenzell innerrhoden': 'Appenzell Innerrhoden',
  'appenzell ausserrhoden': 'Appenzell Ausserrhoden',
  'bern': 'Bern',
  'basel-landschaft': 'Basel-Landschaft',
  'basel-stadt': 'Basel-Stadt',
  'fribourg': 'Fribourg',
  'genève': 'Genève',
  'glarus': 'Glarus',
  'graubünden': 'Graubünden',
  // 'jura': 'Jura', -- duplicate in france
  'luzern': 'Luzern',
  'neuchâtel': 'Neuchâtel',
  'nidwalden': 'Nidwalden',
  'obwalden': 'Obwalden',
  'sankt gallen': 'Sankt Gallen',
  'schaffhausen': 'Schaffhausen',
  'solothurn': 'Solothurn',
  'schwyz': 'Schwyz',
  'thurgau': 'Thurgau',
  'ticino': 'Ticino',
  'uri': 'Uri',
  'vaud': 'Vaud',
  'valais': 'Valais',
  'zug': 'Zug',
  'zürich': 'Zürich',

  // Taiwan
  'changhua': 'Changhua',
  'chiayi': 'Chiayi',
  'hsinchu': 'Hsinchu',
  'hualien': 'Hualien',
  'yilan': 'Yilan',
  'keelung': 'Keelung',
  'kaohsiung': 'Kaohsiung',
  'kinmen': 'Kinmen',
  'lienchiang': 'Lienchiang',
  'miaoli': 'Miaoli',
  'nantou': 'Nantou',
  'new taipei': 'New Taipei',
  'penghu': 'Penghu',
  'pingtung': 'Pingtung',
  'taoyuan': 'Taoyuan',
  'tainan': 'Tainan',
  'taipei': 'Taipei',
  'taitung': 'Taitung',
  'taichung': 'Taichung',
  'yunlin': 'Yunlin',

  // United Kingdom
  'armagh, banbridge and craigavon': 'Armagh, Banbridge and Craigavon',
  'aberdeenshire': 'Aberdeenshire',
  'aberdeen city': 'Aberdeen City',
  'argyll and bute': 'Argyll and Bute',
  'isle of anglesey': 'Isle of Anglesey',
  'ards and north down': 'Ards and North Down',
  'antrim and newtownabbey': 'Antrim and Newtownabbey',
  'angus': 'Angus',
  'armagh': 'Armagh',
  'bath and north east somerset': 'Bath and North East Somerset',
  'blackburn with darwen': 'Blackburn with Darwen',
  'bedford': 'Bedford',
  'barking and dagenham': 'Barking and Dagenham',
  'brent': 'Brent',
  'bexley': 'Bexley',
  'belfast': 'Belfast',
  'bridgend': 'Bridgend',
  'blaenau gwent': 'Blaenau Gwent',
  'birmingham': 'Birmingham',
  'buckinghamshire': 'Buckinghamshire',
  'bournemouth': 'Bournemouth',
  'barnet': 'Barnet',
  'brighton and hove': 'Brighton and Hove',
  'barnsley': 'Barnsley',
  'bolton': 'Bolton',
  'blackpool': 'Blackpool',
  'bracknell forest': 'Bracknell Forest',
  'bradford': 'Bradford',
  'bromley': 'Bromley',
  'bristol': 'Bristol',
  'bury': 'Bury',
  'cambridgeshire': 'Cambridgeshire',
  'caerphilly': 'Caerphilly',
  'central bedfordshire': 'Central Bedfordshire',
  'causeway coast and glens': 'Causeway Coast and Glens',
  'ceredigion': 'Ceredigion',
  'cheshire east': 'Cheshire East',
  'cheshire west and chester': 'Cheshire West and Chester',
  'calderdale': 'Calderdale',
  'clackmannanshire': 'Clackmannanshire',
  'cumbria': 'Cumbria',
  'camden': 'Camden',
  'carmarthenshire': 'Carmarthenshire',
  'cornwall': 'Cornwall',
  'coventry': 'Coventry',
  'cardiff': 'Cardiff',
  'croydon': 'Croydon',
  'conwy': 'Conwy',
  'darlington': 'Darlington',
  'derbyshire': 'Derbyshire',
  'denbighshire': 'Denbighshire',
  'derby': 'Derby',
  'devon': 'Devon',
  'dumfries and galloway': 'Dumfries and Galloway',
  'doncaster': 'Doncaster',
  'dundee city': 'Dundee City',
  'dorset': 'Dorset',
  'derry and strabane': 'Derry and Strabane',
  'dudley': 'Dudley',
  'durham': 'Durham',
  'ealing': 'Ealing',
  'east ayrshire': 'East Ayrshire',
  'edinburgh': 'Edinburgh',
  'east dunbartonshire': 'East Dunbartonshire',
  'east lothian': 'East Lothian',
  'eilean siar': 'Eilean Siar',
  'enfield': 'Enfield',
  'east renfrewshire': 'East Renfrewshire',
  'east riding of yorkshire': 'East Riding of Yorkshire',
  'essex': 'Essex',
  'east sussex': 'East Sussex',
  'falkirk': 'Falkirk',
  'fife': 'Fife',
  'flintshire': 'Flintshire',
  'fermanagh and omagh': 'Fermanagh and Omagh',
  'gateshead': 'Gateshead',
  'glasgow city': 'Glasgow City',
  'gloucestershire': 'Gloucestershire',
  'greenwich': 'Greenwich',
  'gwynedd': 'Gwynedd',
  'halton': 'Halton',
  'hampshire': 'Hampshire',
  'havering': 'Havering',
  'hackney': 'Hackney',
  'herefordshire': 'Herefordshire',
  'hillingdon': 'Hillingdon',
  'highland': 'Highland',
  'hammersmith and fulham': 'Hammersmith and Fulham',
  'hounslow': 'Hounslow',
  'hartlepool': 'Hartlepool',
  'hertfordshire': 'Hertfordshire',
  'harrow': 'Harrow',
  'haringey': 'Haringey',
  'isles of scilly': 'Isles of Scilly',
  'isle of wight': 'Isle of Wight',
  'islington': 'Islington',
  'inverclyde': 'Inverclyde',
  'kensington and chelsea': 'Kensington and Chelsea',
  'kent': 'Kent',
  'kingston upon hull': 'Kingston upon Hull',
  'kirklees': 'Kirklees',
  'kingston upon thames': 'Kingston upon Thames',
  'knowsley': 'Knowsley',
  'lancashire': 'Lancashire',
  'lisburn and castlereagh': 'Lisburn and Castlereagh',
  'lambeth': 'Lambeth',
  'leicester': 'Leicester',
  'leeds': 'Leeds',
  'leicestershire': 'Leicestershire',
  'lewisham': 'Lewisham',
  'lincolnshire': 'Lincolnshire',
  'liverpool': 'Liverpool',
  'london': 'London',
  'luton': 'Luton',
  'manchester': 'Manchester',
  'middlesbrough': 'Middlesbrough',
  'medway': 'Medway',
  'mid and east antrim': 'Mid and East Antrim',
  'milton keynes': 'Milton Keynes',
  'midlothian': 'Midlothian',
  'monmouthshire': 'Monmouthshire',
  'merton': 'Merton',
  'moray': 'Moray',
  'merthyr tydfil': 'Merthyr Tydfil',
  'mid ulster': 'Mid Ulster',
  'north ayrshire': 'North Ayrshire',
  'northumberland': 'Northumberland',
  'north east lincolnshire': 'North East Lincolnshire',
  'newcastle upon tyne': 'Newcastle upon Tyne',
  'norfolk': 'Norfolk',
  'nottingham': 'Nottingham',
  'north lanarkshire': 'North Lanarkshire',
  'north lincolnshire': 'North Lincolnshire',
  'newry, mourne and down': 'Newry, Mourne and Down',
  'north somerset': 'North Somerset',
  'northamptonshire': 'Northamptonshire',
  'neath port talbot': 'Neath Port Talbot',
  'nottinghamshire': 'Nottinghamshire',
  'north tyneside': 'North Tyneside',
  'newham': 'Newham',
  'newport': 'Newport',
  'north yorkshire': 'North Yorkshire',
  'oldham': 'Oldham',
  'orkney islands': 'Orkney Islands',
  'oxfordshire': 'Oxfordshire',
  'pembrokeshire': 'Pembrokeshire',
  'perth and kinross': 'Perth and Kinross',
  'plymouth': 'Plymouth',
  'poole': 'Poole',
  'portsmouth': 'Portsmouth',
  'powys': 'Powys',
  'peterborough': 'Peterborough',
  'redcar and cleveland': 'Redcar and Cleveland',
  'rochdale': 'Rochdale',
  'rhondda, cynon, taff': 'Rhondda, Cynon, Taff',
  'redbridge': 'Redbridge',
  'reading': 'Reading',
  'renfrewshire': 'Renfrewshire',
  'richmond upon thames': 'Richmond upon Thames',
  'rotherham': 'Rotherham',
  'rutland': 'Rutland',
  'sandwell': 'Sandwell',
  'south ayrshire': 'South Ayrshire',
  'scottish borders': 'Scottish Borders',
  'suffolk': 'Suffolk',
  'sefton': 'Sefton',
  'south gloucestershire': 'South Gloucestershire',
  'sheffield': 'Sheffield',
  'st. helens': 'St. Helens',
  'shropshire': 'Shropshire',
  'stockport': 'Stockport',
  'salford': 'Salford',
  'slough': 'Slough',
  'south lanarkshire': 'South Lanarkshire',
  'sunderland': 'Sunderland',
  'solihull': 'Solihull',
  'somerset': 'Somerset',
  'southend-on-sea': 'Southend-on-Sea',
  'surrey': 'Surrey',
  'stoke-on-trent': 'Stoke-on-Trent',
  'stirling': 'Stirling',
  'southampton': 'Southampton',
  'sutton': 'Sutton',
  'staffordshire': 'Staffordshire',
  'stockton-on-tees': 'Stockton-on-Tees',
  'south tyneside': 'South Tyneside',
  'swansea': 'Swansea',
  'swindon': 'Swindon',
  'southwark': 'Southwark',
  'tameside': 'Tameside',
  'telford and wrekin': 'Telford and Wrekin',
  'thurrock': 'Thurrock',
  'torbay': 'Torbay',
  'torfaen': 'Torfaen',
  'trafford': 'Trafford',
  'tower hamlets': 'Tower Hamlets',
  'vale of glamorgan, the': 'Vale of Glamorgan, The',
  'warwickshire': 'Warwickshire',
  'west berkshire': 'West Berkshire',
  'west dunbartonshire': 'West Dunbartonshire',
  'waltham forest': 'Waltham Forest',
  'wigan': 'Wigan',
  'wiltshire': 'Wiltshire',
  'wakefield': 'Wakefield',
  'walsall': 'Walsall',
  'west lothian': 'West Lothian',
  'wolverhampton': 'Wolverhampton',
  'wandsworth': 'Wandsworth',
  'windsor and maidenhead': 'Windsor and Maidenhead',
  'wokingham': 'Wokingham',
  'worcestershire': 'Worcestershire',
  'wirral': 'Wirral',
  'warrington': 'Warrington',
  'wrexham': 'Wrexham',
  'westminster': 'Westminster',
  'west sussex': 'West Sussex',
  'york': 'York',
  'shetland islands': 'Shetland Islands',

  // United States
  'alabama': 'Alabama',
  'alaska': 'Alaska',
  'american_samoa': 'American Samoa',
  'arizona': 'Arizona',
  'arkansas': 'Arkansas',
  'california': 'California',
  'colorado': 'Colorado',
  'connecticut': 'Connecticut',
  'delaware': 'Delaware',
  'district_of_columbia': 'District Of Columbia',
  'federated_states_of_micronesia': 'Federated States Of Micronesia',
  'florida': 'Florida',
  'georgia': 'Georgia',
  'guam': 'Guam',
  'hawaii': 'Hawaii',
  'idaho': 'Idaho',
  'illinois': 'Illinois',
  'indiana': 'Indiana',
  'iowa': 'Iowa',
  'kansas': 'Kansas',
  'kentucky': 'Kentucky',
  'louisiana': 'Louisiana',
  'maine': 'Maine',
  'marshall_islands': 'Marshall Islands',
  'maryland': 'Maryland',
  'massachusetts': 'Massachusetts',
  'michigan': 'Michigan',
  'minnesota': 'Minnesota',
  'mississippi': 'Mississippi',
  'missouri': 'Missouri',
  'montana': 'Montana',
  'nebraska': 'Nebraska',
  'nevada': 'Nevada',
  'new_hampshire': 'New Hampshire',
  'new_jersey': 'New Jersey',
  'new_mexico': 'New Mexico',
  'new_york': 'New York',
  'north_carolina': 'North Carolina',
  'north_dakota': 'North Dakota',
  'northern_mariana_islands': 'Northern Mariana Islands',
  'ohio': 'Ohio',
  'oklahoma': 'Oklahoma',
  'oregon': 'Oregon',
  'palau': 'Palau',
  'pennsylvania': 'Pennsylvania',
  'puerto_rico': 'Puerto Rico',
  'rhode_island': 'Rhode Island',
  'south_carolina': 'South Carolina',
  'south_dakota': 'South Dakota',
  'tennessee': 'Tennessee',
  'texas': 'Texas',
  'utah': 'Utah',
  'vermont': 'Vermont',
  'virgin_islands': 'Virgin Islands',
  'virginia': 'Virginia',
  'washington': 'Washington',
  'west_virginia': 'West Virginia',
  'wisconsin': 'Wisconsin',
  'wyoming': 'Wyoming',

  // Provinces
  'alberta': 'Alberta',
  'british_columbia': 'British Columbia',
  'manitoba': 'Manitoba',
  'new_brunswick': 'New Brunswick',
  'newfoundland_and_labrador': 'Newfoundland and Labrador',
  'nova_scotia': 'Nova Scotia',
  'northwest_territories': 'Northwest Territories',
  'nunavut': 'Nunavut',
  'ontario': 'Ontario',
  'prince_edward_island': 'Prince Edward Island',
  'quebec': 'Québec',
  'saskatchewan': 'Saskatchewan',
  'yukon': 'Yukon',
};
