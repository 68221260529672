import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export type CompletionResponse = {
  completion: string;
};

export type AiPrompts = {
  version: number;
  title_prompt: string;
  objective_prompt: string;
  eligibility_prompt: string;
};

export type NewAiPrompts = Omit<AiPrompts, 'version'>;

export async function getPrompts(): Promise<AiPrompts | null> {
  const response = await authenticatedFetch(`${BASE_URL}/ai_prompts`);

  if (!response.ok) {
    return null;
  }
  return response.json();
}

export async function setPrompts(
  prompts: NewAiPrompts,
): Promise<AiPrompts | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(`${BASE_URL}/ai_prompts`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(prompts),
  });

  if (!response.ok) {
    return null;
  }
  return response.json();
}

export async function doCompletion(
  prompt: string,
  details: string,
): Promise<CompletionResponse | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(`${BASE_URL}/ai_completion`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      prompt,
      details,
    }),
  });

  if (!response.ok) {
    return null;
  }

  return response.json();
}
