/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components/macro';
import LabeledInput from 'components/LabeledInput';
import Select, { SingleValue } from 'react-select';
import TrialIntervention from 'common/types/TrialIntervention';
import AddDeleteButtons from 'components/AddDeleteButtons/AddDeleteButtons';

const StyledMainWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledInputWrapper = styled.div`
  width: 70%;
  margin-left: 10px;
`;
const StyledSelectWrapper = styled.div`
  width: 30%;
  height: 30px;
`;
export type InputProps = {
  interventionArray: TrialIntervention[] | undefined;
  onChangeIntervention: (newValue: TrialIntervention[]) => void;
};

// Component
const InterventionEdit = ({
  interventionArray,
  onChangeIntervention,
}: InputProps) => {
  const options = [
    { value: 'Other', label: 'Other' },
    { value: 'Diagnostic Test', label: 'Diagnostic Test' },
    { value: 'Drug', label: 'Drug' },
    { value: 'Behavioral', label: 'Behavioral' },
    { value: 'Device', label: 'Device' },
    { value: 'Dietary Supplement', label: 'Dietary Supplement' },
    { value: 'Biological', label: 'Biological' },
    { value: 'Radiation', label: 'Radiation' },
    { value: 'Procedure', label: 'Procedure' },
    { value: 'Combination Product', label: 'Combination Product' },
    { value: 'Genetic', label: 'Genetic' },
  ];
  return (
    <>
      {interventionArray &&
        interventionArray.map((intervention, index) => (
          <StyledMainWrapper key={`${index}`}>
            <StyledSelectWrapper>
              <Select
                options={options}
                value={
                  intervention.intervention_type && {
                    value: intervention.intervention_type,
                    label: intervention.intervention_type,
                  }
                }
                onChange={(
                  updatedValue: SingleValue<
                    '' | { label: string; value: string }
                  >,
                ) => {
                  const updated_intervention = interventionArray.map(
                    (intervention, i) => {
                      if (index === i)
                        return {
                          ...intervention,
                          ['intervention_type']: updatedValue
                            ? updatedValue.value
                            : '',
                        };
                      return intervention;
                    },
                  );
                  onChangeIntervention(updated_intervention);
                }}
              />
            </StyledSelectWrapper>
            <StyledInputWrapper>
              <LabeledInput
                hasError={false}
                label={''}
                placeholder="Name"
                type="text"
                value={intervention.intervention_name}
                onChange={(evt) => {
                  const updated_intervention = interventionArray.map(
                    (intervention, i) => {
                      if (index === i)
                        return {
                          ...intervention,
                          ['intervention_name']: evt.target.value,
                        };
                      return intervention;
                    },
                  );
                  onChangeIntervention(updated_intervention);
                }}
              />
            </StyledInputWrapper>
            <AddDeleteButtons
              onAddClicked={() => {
                const new_intervention = {
                  arm_group_label: '',
                  description: '',
                  intervention_name: '',
                  intervention_type: '',
                };
                const updatedInterventionArray = [...interventionArray];
                updatedInterventionArray?.splice(
                  index + 1,
                  0,
                  new_intervention,
                );
                onChangeIntervention(updatedInterventionArray);
              }}
              onDeleteClicked={() => {
                const updatedInterventionArray = [...interventionArray];
                updatedInterventionArray?.splice(index, 1);
                onChangeIntervention(updatedInterventionArray);
              }}
            />
          </StyledMainWrapper>
        ))}

      {(!interventionArray || interventionArray.length == 0) && (
        <AddDeleteButtons
          isDelete={false}
          onAddClicked={() => {
            onChangeIntervention([
              {
                arm_group_label: '',
                description: '',
                intervention_name: '',
                intervention_type: '',
              },
            ]);
          }}
        />
      )}
    </>
  );
};

export default InterventionEdit;
