import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IconSelect from 'components/IconSelect';
import SelectTitle from 'components/SelectTitle';

import { GENDERS } from 'modules/Search/constants';

// Styled Components
const StyledGenderSelect = styled.div`
  display: flex;
  flex-direction: column;
`;

export type GenderSelectProps = {
  selectedGenders: string[];
  isDisabled: boolean;
  onGendersChange: (values: string[]) => void;
  theme?: DefaultTheme;
};

// Component
const GenderSelect = ({
  selectedGenders,
  isDisabled,
  onGendersChange,
}: GenderSelectProps) => {
  const { t } = useTranslation(['search']);

  const genderOptions = Object.values(GENDERS).map((gender) => ({
    text: t(gender.i18nKey),
    value: gender.value,
  }));

  return (
    <StyledGenderSelect>
      <SelectTitle>{t('search:gender_title')}</SelectTitle>
      <IconSelect
        options={genderOptions}
        selectedValues={selectedGenders}
        onChange={onGendersChange}
        disabled={isDisabled}
      />
    </StyledGenderSelect>
  );
};

GenderSelect.propTypes = {
  selectedGenders: PropTypes.arrayOf(PropTypes.string).isRequired,
  onGendersChange: PropTypes.func.isRequired,
};

export default GenderSelect;
