import {
  getResourceLinks,
  putResourceLinks,
  ResourceLink,
} from 'common/api/resourceLinks';
import Button from 'components/Button/Button';
import SuccessMessage from 'components/SuccessMessage/SuccessMessage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components';

const StyledResourceLinks = styled.div`
  ${(props) => props.theme.paddedContent};
  flex-grow: 1;
`;

const StyledHeading = styled.h1`
  margin: 24px 0;
  text-align: center;
`;

const StyledError = styled.div`
  color: ${(props) => props.theme.colors.error};
`;

const StyledResourcesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 32px;

  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  label {
    display: flex;
    flex-direction: column;
  }

  input,
  textarea {
    border: 1px solid ${(props) => props.theme.colors.inputBorder};
    border-radius: 5px;
    box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};
  }

  textarea {
    box-sizing: border-box;
    height: 200px;
    max-width: 100%;
    min-width: 100%;
  }

  fieldset {
    border: 1px solid ${(props) => props.theme.colors.inputBorder};
  }
`;

const StyledResourceItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 16px;
  box-shadow: 0 0 20px -10px ${(props) => props.theme.rawColors.black40};
`;

const StyledLinksList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const StyledLinkItem = styled.li`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.mediumBackground};
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 16px;

  label,
  input {
    font-size: ${(props) => props.theme.fontSizes.small};
  }

  input {
    padding: 8px;
  }
`;

const StyledItemControls = styled.div`
  display: flex;
  gap: 4px;
  position: absolute;
  top: 4px;
  right: 4px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    background-color: transparent;
    border: 2px solid ${(props) => props.theme.colors.darkButtonBorder};
    border-radius: 16px;
  }
`;

const StyledLinkControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const StyledMessageArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const StyledControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export type ResourceLinksProps = {
  theme?: DefaultTheme;
};

const sortResourceLinks = (a: ResourceLink, b: ResourceLink) =>
  a.order - b.order;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ResourceLinks = (props: ResourceLinksProps) => {
  const { t } = useTranslation();
  const [areResourceLinksChecked, setAreResourceLinksChecked] =
    useState<boolean>(false);
  const [resourceLinks, setResourceLinks] = useState<Partial<ResourceLink>[]>(
    [],
  );
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  useEffect(() => {
    const loadResourceLinks = async () => {
      try {
        const resourceLinksResponse = await getResourceLinks();
        if (!resourceLinksResponse) {
          setErrorKey('common:unknown_error');
          return;
        }

        setResourceLinks(
          resourceLinksResponse.resource_links.sort(sortResourceLinks),
        );
      } catch (ex) {
        setErrorKey('common:unknown_error');
      }
      setAreResourceLinksChecked(true);
    };

    loadResourceLinks();
  }, []);

  const onSave = async () => {
    try {
      // Filter out empty items
      const filteredResourceLinks = resourceLinks
        .filter(({ title, content }) => title || content)
        .map((resourceLink) => ({
          ...resourceLink,
          links: resourceLink.links?.filter(({ url }) => url),
        }));

      // Add ordering
      const orderedResourceLinks = filteredResourceLinks.map(
        (resourceLink, index) => ({ ...resourceLink, order: index }),
      );

      const resourceLinksResponse = await putResourceLinks(
        orderedResourceLinks,
      );

      if (!resourceLinksResponse) {
        setErrorKey('common:unknown_error');
        return;
      }

      setResourceLinks(
        resourceLinksResponse.resource_links.sort(sortResourceLinks),
      );

      setSaveSuccess(true);
      setTimeout(() => {
        setSaveSuccess(false);
      }, 5000);
    } catch (ex) {
      setErrorKey('common:unknown_error');
    }
  };

  return (
    <StyledResourceLinks>
      <StyledHeading>{t('common:resources')}</StyledHeading>
      {!areResourceLinksChecked && <span>{t('common:loading')}</span>}
      <StyledResourcesList>
        {resourceLinks.map((resourceLink, index) => (
          <StyledResourceItem key={resourceLink.id ?? index}>
            <label>
              <span>{t('resourceLinks:title')}</span>
              <input
                type="text"
                value={resourceLink.title}
                onChange={(evt) => {
                  const updatedResourceLinks = [...resourceLinks];
                  const updatedResourceLink = { ...resourceLink };
                  updatedResourceLink.title = evt.target.value;
                  updatedResourceLinks[index] = updatedResourceLink;
                  setResourceLinks(updatedResourceLinks);
                }}
              />
            </label>
            <label>
              <span>{t('resourceLinks:content')}</span>
              <textarea
                value={resourceLink.content}
                onChange={(evt) => {
                  const updatedResourceLinks = [...resourceLinks];
                  const updatedResourceLink = { ...resourceLink };
                  updatedResourceLink.content = evt.target.value;
                  updatedResourceLinks[index] = updatedResourceLink;
                  setResourceLinks(updatedResourceLinks);
                }}
              />
            </label>
            <fieldset>
              <legend>{t('resourceLinks:links')}</legend>
              <StyledLinksList>
                {resourceLink.links?.map((link, linkIndex) => (
                  <StyledLinkItem key={linkIndex}>
                    <label>
                      <span>{t('resourceLinks:title')}</span>
                      <input
                        type="text"
                        value={link.title}
                        onChange={(evt) => {
                          const updatedResourceLinks = [...resourceLinks];
                          const updatedResourceLink = { ...resourceLink };
                          const updatedLinks = updatedResourceLink.links!;
                          const updatedLink = { ...link };
                          updatedLink.title = evt.target.value;
                          updatedLinks[linkIndex] = updatedLink;
                          updatedResourceLink.links = updatedLinks;
                          updatedResourceLinks[index] = updatedResourceLink;
                          setResourceLinks(updatedResourceLinks);
                        }}
                      />
                    </label>
                    <label>
                      <span>{t('resourceLinks:url')}</span>
                      <input
                        type="text"
                        value={link.url}
                        onChange={(evt) => {
                          const updatedResourceLinks = [...resourceLinks];
                          const updatedResourceLink = { ...resourceLink };
                          const updatedLinks = updatedResourceLink.links!;
                          const updatedLink = { ...link };
                          updatedLink.url = evt.target.value;
                          updatedLinks[linkIndex] = updatedLink;
                          updatedResourceLink.links = updatedLinks;
                          updatedResourceLinks[index] = updatedResourceLink;
                          setResourceLinks(updatedResourceLinks);
                        }}
                      />
                    </label>
                    <StyledItemControls>
                      <button
                        title={t('common:delete')}
                        onClick={() => {
                          const updatedResourceLinks = [...resourceLinks];
                          const updatedResourceLink = { ...resourceLink };
                          updatedResourceLink.links =
                            updatedResourceLink.links?.filter(
                              (_, filterIndex) => filterIndex !== linkIndex,
                            );
                          updatedResourceLinks[index] = updatedResourceLink;
                          setResourceLinks(updatedResourceLinks);
                        }}
                      >
                        <span role="img" aria-label={t('common:delete')}>
                          ⤫
                        </span>
                      </button>
                    </StyledItemControls>
                  </StyledLinkItem>
                ))}
              </StyledLinksList>

              <StyledLinkControls>
                <Button
                  small
                  onClick={() => {
                    const updatedResourceLinks = [...resourceLinks];
                    const updatedResourceLink = { ...resourceLink };
                    updatedResourceLink.links = updatedResourceLink.links
                      ? [...updatedResourceLink.links, { title: '', url: '' }]
                      : [{ title: '', url: '' }];
                    updatedResourceLinks[index] = updatedResourceLink;
                    setResourceLinks(updatedResourceLinks);
                  }}
                >
                  {t('resourceLinks:add_link')}
                </Button>
              </StyledLinkControls>
            </fieldset>

            <StyledItemControls>
              {index > 0 && (
                <button
                  title={t('common:move_up')}
                  onClick={() => {
                    const updatedResourceLinks = [...resourceLinks];
                    updatedResourceLinks.splice(
                      index - 1,
                      0,
                      updatedResourceLinks.splice(index, 1)[0],
                    );
                    setResourceLinks(updatedResourceLinks);
                  }}
                >
                  <span role="img" aria-label={t('common:move_up')}>
                    🡱
                  </span>
                </button>
              )}
              {index < resourceLinks.length - 1 && (
                <button
                  title={t('common:move_down')}
                  onClick={() => {
                    const updatedResourceLinks = [...resourceLinks];
                    updatedResourceLinks.splice(
                      index + 1,
                      0,
                      updatedResourceLinks.splice(index, 1)[0],
                    );
                    setResourceLinks(updatedResourceLinks);
                  }}
                >
                  <span role="img" aria-label={t('common:move_down')}>
                    🡳
                  </span>
                </button>
              )}
              <button
                title={t('common:delete')}
                onClick={() =>
                  setResourceLinks(
                    resourceLinks.filter(
                      (_, filterIndex) => filterIndex !== index,
                    ),
                  )
                }
              >
                <span role="img" aria-label={t('common:delete')}>
                  ⤫
                </span>
              </button>
            </StyledItemControls>
          </StyledResourceItem>
        ))}
      </StyledResourcesList>
      <StyledMessageArea>
        {errorKey && <StyledError>{t(errorKey)}</StyledError>}
        <SuccessMessage
          message={t('resourceLinks:save_successful')}
          shown={saveSuccess}
        />
      </StyledMessageArea>
      <StyledControls>
        <Button
          onClick={() =>
            setResourceLinks([
              ...resourceLinks,
              { title: '', content: '', links: [] },
            ])
          }
        >
          {t('resourceLinks:add_resource')}
        </Button>
        <Button dark onClick={onSave}>
          {t('resourceLinks:save')}
        </Button>
      </StyledControls>
    </StyledResourceLinks>
  );
};

export default ResourceLinks;
