import { DefaultTheme } from 'styled-components/macro';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { getCovidTrials } from 'common/api/covidTrials';
import CovidFilters from 'common/types/CovidFilters';

import AgeInput from 'components/AgeInput';
import LocationSelect from 'components/LocationSelect';
import SearchPage from 'components/SearchPage';
import YesNoInput from 'components/YesNoInput';

import { DEFAULT_FILTERS } from './constants';

import { AgeOption } from 'components/AgeInput/AgeInput';
import { PATHS } from 'common/constants';

// Helper
const getFilterUrl = (filters: CovidFilters) => {
  const filterParams = queryString.stringify(
    { ...filters },
    { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true },
  );
  return `${PATHS.COVID_SEARCH}?${filterParams}`;
};

export type SearchCovidProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include props to support StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchCovid = (props: SearchCovidProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const filters = {
    ...DEFAULT_FILTERS,
    ...queryString.parse(location.search, {
      arrayFormat: 'bracket',
      parseNumbers: true,
      parseBooleans: true,
    }),
  } as CovidFilters;

  const setUpdatedFilters = (updatedFilters: CovidFilters) => {
    // Update the URL for linking
    history.replace(getFilterUrl(updatedFilters));
  };

  const getSetFilter =
    (filter: string) => (value?: string | boolean | number) => {
      // Reset page filter if other filter changes
      const updatedPage = filter !== 'page' ? 1 : filters.page;
      const updatedFilters = {
        ...filters,
        page: updatedPage,
        [filter]: value,
      };

      setUpdatedFilters(updatedFilters);
    };

  const onCountryAndStateChange = (country: string, state?: string) => {
    const updatedFilters = {
      ...filters,
      country,
      state,
    };

    setUpdatedFilters(updatedFilters);
  };

  // Define controls for each step
  // Need to include props to support StyledComponents useTheme
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchControls = [
    // Step #1: Location
    <LocationSelect
      key="location"
      selectedCountry={filters.country}
      selectedState={filters.state}
      title={t('searchCovid:where_to_search')}
      onStateChange={getSetFilter('state')}
      onCountryAndStateChange={onCountryAndStateChange}
    />,

    // Step #2: Age
    <AgeInput
      key="age"
      value={filters.age}
      onChange={(newValue) =>
        getSetFilter('age')((newValue as AgeOption)?.value)
      }
    />,

    // Step #3: Other clinical
    <YesNoInput
      key="other_clinical"
      question={t('searchCovid:are_you_in_other_trial')}
      value={filters.inClinicalTrial}
      onChange={getSetFilter('inClinicalTrial')}
    />,

    // Step #4: Previous clinical trial
    <YesNoInput
      key="previous_clinical_trial"
      question={t('searchCovid:have_previous_trial')}
      value={filters.previousClinicalTrial}
      onChange={getSetFilter('previousClinicalTrial')}
    />,

    // Step #5: Hospitalized
    <YesNoInput
      key="hospitalized"
      question={t('searchCovid:are_you_hospitalized')}
      value={filters.inHospital}
      onChange={getSetFilter('inHospital')}
    />,

    // Step #6: Healthcare Worker
    <YesNoInput
      key="healthcare_worker"
      question={t('searchCovid:are_you_a_healthcare_worker')}
      value={filters.healthcareWorker}
      onChange={getSetFilter('healthcareWorker')}
    />,

    // Step #7: Ventilator
    <YesNoInput
      key="ventilator"
      question={t('searchCovid:do_you_require_a_ventilator')}
      value={filters.requireVentilator}
      onChange={getSetFilter('requireVentilator')}
    />,

    // Step #8: Have immune related diseases
    <YesNoInput
      key="immune_related_diseases"
      question={t('searchCovid:do_you_have_immune_disease')}
      value={filters.haveImmuneDiseases}
      onChange={getSetFilter('haveImmuneDiseases')}
    />,

    // Step #9: On Chemo
    <YesNoInput
      key="on_chemo"
      question={t('searchCovid:are_you_on_chemo')}
      value={filters.onChemotherapy}
      onChange={getSetFilter('onChemotherapy')}
    />,

    // Step #10: Taking immunosuppressants
    <YesNoInput
      key="immunosuppressants"
      question={t('searchCovid:are_you_taking_immuno')}
      value={filters.takingImmunosuppressants}
      onChange={getSetFilter('takingImmunosuppressants')}
    />,

    // Step #11: Pregnant or breastfeeding
    <YesNoInput
      key="pregnant_or_breastfeeding"
      question={t('searchCovid:are_you_pregnant')}
      value={filters.breastfeeding}
      onChange={getSetFilter('breastfeeding')}
    />,
  ];

  return (
    <SearchPage
      // currentStep={currentStep}
      filters={filters}
      resultsTitle={t('searchCovid:results_based_on_selection')}
      searchTitle={t('searchCovid:going_to_ask_questions')}
      searchControls={searchControls}
      title={t('searchCovid:browser_title')}
      trialBaseUrl="/admin/covidtrials"
      onPageSelect={getSetFilter('page')}
      searchTrials={() =>
        getCovidTrials(
          {
            age: filters.age,
            country: filters.country,
            state: filters.state,
            in_clinical_trial: filters.inClinicalTrial,
            previous_clinical_trial: filters.previousClinicalTrial,
            in_hospital: filters.inHospital,
            healthcare_worker: filters.healthcareWorker,
            require_ventilator: filters.requireVentilator,
            have_immune_diseases: filters.haveImmuneDiseases,
            on_chemotherapy: filters.onChemotherapy,
            taking_immunosuppressants: filters.takingImmunosuppressants,
            breastfeeding: filters.breastfeeding,
          },
          filters.page,
        )
      }
    />
  );
};

export default SearchCovid;
