/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import TrialLocation from 'common/types/TrialLocation';
import SectionSubheading from 'components/SectionSubheading';
import COUNTRIES from 'common/countries';
import Select, { SingleValue } from 'react-select';
import AddDeleteButtons from 'components/AddDeleteButtons';
import InputField from 'components/InputField/InputField';

// Styled Components
const StyledLocationSummaryList = styled.div``;

export type LocationSummaryListProps = {
  locations: TrialLocation[] | undefined;
  onChangeLocation: (newValue: TrialLocation[]) => void;
  theme?: DefaultTheme;
};

const StyledWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'input1 input1 input2'
    'input3 input4 input5';
  margin-top: 10px;
  grid-gap: 10px;
  align-items: center;
`;
const StyledInputWrapper = styled.div<{ gridArea?: string }>`
  grid-area: ${(props) => props.gridArea};
`;
const StyledSelectWrapper = styled.div<{ gridArea?: string }>`
  grid-area: ${(props) => props.gridArea};
`;

const StyledActions = styled.div`
  float: right;
  margin-top: -25px;
`;

// Component
const LocationSummaryList = ({
  locations,
  onChangeLocation,
}: LocationSummaryListProps) => {
  const { t } = useTranslation();
  const countryOptions = Object.values(COUNTRIES).map((country) => ({
    value: country?.value,
    label: t(country.i18nKey),
  }));

  const getStateOptions = (selectedCountry: string | undefined) => {
    if (selectedCountry && COUNTRIES[selectedCountry]) {
      const states = COUNTRIES[selectedCountry]?.states
        ? Object.values(COUNTRIES[selectedCountry].states!)
            .sort((a, b) => {
              if (a.value > b.value) return 1;
              if (b.value > a.value) return -1;
              return 0;
            })
            .map((state) => ({
              value: state.value,
              label: state.value,
            }))
        : [];
      return states;
    }
    return [];
  };

  const updateLocation = (
    updateKey: string,
    index: number,
    newValue: string | undefined,
  ) => {
    if (locations) {
      const updatedLocations = locations.map((singleLocation, i) => {
        if (index === i)
          return {
            ...singleLocation,
            ['facility']: {
              ...singleLocation.facility,
              ['address']: {
                ...singleLocation?.facility?.address,
                [updateKey]: newValue,
              },
            },
          };
        return singleLocation;
      });
      onChangeLocation(updatedLocations);
    }
  };
  return (
    <StyledLocationSummaryList>
      <SectionSubheading>{t('trial:locations_label')}</SectionSubheading>
      <>
        {locations &&
          locations.map(
            ({ facility = null }, index) =>
              facility && (
                <div key={index}>
                  <StyledWrapper>
                    <StyledInputWrapper gridArea="input1">
                      <InputField
                        placeholder="Name"
                        type="text"
                        value={facility.name}
                        onChange={(evt) => {
                          const updatedLocations = locations.map(
                            (singleLocation, i) => {
                              if (index === i)
                                return {
                                  ...singleLocation,
                                  ['facility']: {
                                    ...singleLocation.facility,
                                    ['name']: evt.target.value,
                                  },
                                };
                              return singleLocation;
                            },
                          );
                          onChangeLocation(updatedLocations);
                        }}
                      />
                    </StyledInputWrapper>

                    <StyledSelectWrapper gridArea="input2">
                      <Select
                        placeholder={'Country'}
                        options={countryOptions}
                        value={
                          facility?.address?.country && {
                            value: facility.address.country,
                            label: facility.address.country,
                          }
                        }
                        onChange={(
                          updatedValue: SingleValue<
                            | ''
                            | {
                                label: string | undefined;
                                value: string | undefined;
                              }
                          >,
                        ) => {
                          updateLocation(
                            'country',
                            index,
                            updatedValue ? updatedValue.value : '',
                          );
                        }}
                      />
                    </StyledSelectWrapper>

                    <StyledSelectWrapper gridArea="input3">
                      <Select
                        options={getStateOptions(facility?.address?.country)}
                        value={
                          facility?.address?.state && {
                            value: facility.address.state,
                            label: facility.address.state,
                          }
                        }
                        onChange={(
                          updatedValue: SingleValue<
                            | ''
                            | {
                                label: string | undefined;
                                value: string | undefined;
                              }
                          >,
                        ) => {
                          updateLocation(
                            'state',
                            index,
                            updatedValue ? updatedValue.value : '',
                          );
                        }}
                        placeholder={'State'}
                      />
                    </StyledSelectWrapper>

                    <StyledInputWrapper gridArea="input4">
                      <InputField
                        hasError={false}
                        placeholder="City"
                        type="text"
                        value={facility?.address?.city}
                        onChange={(evt) => {
                          updateLocation('city', index, evt.target.value);
                        }}
                      />
                    </StyledInputWrapper>

                    <StyledInputWrapper gridArea="input5">
                      <InputField
                        hasError={false}
                        placeholder="Zip Code"
                        type="text"
                        value={facility?.address?.zip}
                        onChange={(evt) => {
                          updateLocation('zip', index, evt.target.value);
                        }}
                      />
                    </StyledInputWrapper>
                  </StyledWrapper>
                  <StyledActions>
                    <AddDeleteButtons
                      onAddClicked={() => {
                        const new_location = {
                          facility: {
                            address: {
                              country: '',
                              state: '',
                              city: '',
                              zip: '',
                            },
                            name: '',
                          },
                        };
                        const newArray = [...locations];
                        newArray?.splice(index + 1, 0, new_location);
                        onChangeLocation(newArray);
                      }}
                      onDeleteClicked={() => {
                        const newArray = [...locations];
                        newArray?.splice(index, 1);
                        onChangeLocation(newArray);
                      }}
                    />
                  </StyledActions>
                </div>
              ),
          )}

        {(!locations || locations.length == 0) && (
          <AddDeleteButtons
            isDelete={false}
            onAddClicked={() => {
              onChangeLocation([
                {
                  facility: {
                    address: {
                      country: '',
                      state: '',
                      city: '',
                      zip: '',
                    },
                    name: '',
                  },
                },
              ]);
            }}
          />
        )}
      </>
    </StyledLocationSummaryList>
  );
};

export default LocationSummaryList;
