import AddDeleteButtons from 'components/AddDeleteButtons';
import InputField from 'components/InputField';
import styled, { DefaultTheme } from 'styled-components';

const StyledContainer = styled.div``;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
`;

export type ListInputProps = {
  items: string[];
  theme?: DefaultTheme;
  onChange: (updatedItems: string[]) => void;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>;

const ListInput = ({ items, onChange, ...rest }: ListInputProps) => (
  <StyledContainer {...rest}>
    {items.map((item, index) => (
      <StyledItem key={`${index}`}>
        <InputField
          value={item}
          type="input"
          onChange={(evt) =>
            onChange(
              items.map((currentValue, i) =>
                i === index ? evt.target.value : currentValue,
              ),
            )
          }
        />
        <AddDeleteButtons
          onAddClicked={() => {
            const updatedItems = [...items];
            updatedItems.splice(index + 1, 0, '');
            onChange(updatedItems);
          }}
          onDeleteClicked={() => {
            const updatedItems = [...items];
            updatedItems.splice(index, 1);
            onChange(updatedItems);
          }}
        />
      </StyledItem>
    ))}
  </StyledContainer>
);

export default ListInput;
