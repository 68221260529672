import { useState } from 'react';

import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar';

import {
  FiArrowLeftCircle,
  FiArrowRightCircle,
  FiLogOut,
} from 'react-icons/fi';

import logoImg from 'assets/HealMary.svg';
import useRequireAuth from 'hooks/useRequireAuth';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';

const StyledLogoLink = styled(Link)`
  border-bottom: none;
  z-index: 100;
`;

const StyledLogo = styled.img`
  display: block;
  width: 100%;

  @media ${(props) => props.theme.devices.tablet} {
    height: 31px;
  }
`;

const StyledProSidebar = styled(ProSidebar)`
  position: fixed;
  width: 220px;
  z-index: 1000;

  .pro-sidebar {
    height: 100vh;
    width: 100%;
    min-width: 100%;
  }

  .closemenu {
    color: #000;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 55px;
    cursor: pointer;
  }

  .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }

  .pro-sidebar-inner {
    background-color: white;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }

  .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }

  .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    font-weight: bold;
  }

  .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
  }

  .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #000;
    margin: 10px 0px;
    font-weight: bold;
  }

  .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #7084a0;
    color: #ffff;
    border-radius: 3px;
  }

  .pro-sidebar-inner
    .pro-sidebar-layout
    ul
    .pro-inner-item
    .pro-icon-wrapper
    .pro-item-content {
    color: #000;
  }

  .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #344968 0%, #7084a0 100%);
    .pro-item-content {
      color: white;
    }
  }

  .logo {
    padding: 20px;
  }
`;

export type SidebarProps = {
  theme?: DefaultTheme;
};
const Sidebar = () => {
  const location = useLocation();
  const { t } = useTranslation();
  useRequireAuth();

  const [menuCollapse, setMenuCollapse] = useState(true);

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  return (
    <StyledProSidebar
      onMouseEnter={() => setMenuCollapse(false)}
      onMouseLeave={() => setMenuCollapse(true)}
      collapsed={menuCollapse}
    >
      <SidebarHeader>
        <div className="logotext">
          <p>
            {menuCollapse ? (
              'HM'
            ) : (
              <StyledLogoLink to="/">
                <StyledLogo src={logoImg} alt={t('common:heal_mary')} />
              </StyledLogoLink>
            )}
          </p>
        </div>
        <div aria-hidden="true" className="closemenu" onClick={menuIconClick}>
          {/* changing menu collapse icon on click */}
          {menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square">
          <MenuItem active={location.pathname.indexOf('/search') !== -1}>
            {t('common:search')}
            <Link to="/admin/search" />
          </MenuItem>
          <MenuItem active={location.pathname.indexOf('/covid') !== -1}>
            {t('common:covid_search')}
            <Link to="/admin/covid" />
          </MenuItem>
          <MenuItem active={location.pathname.indexOf('/resources') !== -1}>
            {t('common:resources')}
            <Link to="/admin/resources" />
          </MenuItem>
          <MenuItem
            active={location.pathname.indexOf('/integrator_clients') !== -1}
          >
            {t('common:integrator_clients')}
            <Link to="/admin/integrator_clients" />
          </MenuItem>
          <MenuItem active={location.pathname.indexOf('/trial_tagging') !== -1}>
            {t('common:trial_tagging')}
            <Link to="/admin/trial_tagging" />
          </MenuItem>
          <MenuItem active={location.pathname.indexOf('/ai') !== -1}>
            {t('common:ai')}
            <Link to="/admin/ai" />
          </MenuItem>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu iconShape="square">
          <MenuItem icon={<FiLogOut />}>
            {t('common:logout')} <Link to="/admin/signin?action=signout" />
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </StyledProSidebar>
  );
};

export default Sidebar;
